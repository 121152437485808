export function validateInput(e) {
  const targetValue = e.target?.value;
  const currentValue = e.current?.value;
  const hasValue = targetValue || currentValue;
  const target = e.target || e.current;
  const field = e.target?.id;

  switch (field) {
    case "currentZip":
      handleValidationResult(isUSAZipCode(hasValue), target);
      break;
    case "primaryPhone":
    case "secondaryPhone":
      handleValidationResult(isValidPhone(hasValue), target);
      break;
    case "primaryEmail":
    case "secondaryEmail":
      handleValidationResult(isValidEmail(hasValue), target);
      break;
    default:
      handleValidationResult(hasValue, target)
      break;
  }
}

function handleValidationResult(isValid, target) {
  target.classList.remove("is-valid", "is-invalid");
  target.classList.add(isValid ? "is-valid" : "is-invalid");
}
export const isUSAZipCode = (str) => {
  const trimStr = str?.trim();

  return /^[0-9]{5}?$/.test(trimStr);
};
export const isValidPhone = (str) => {
  return /^[0-9]{10}?$/.test(str);
};
export const isValidEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
export const checkInputRefValue = (inputRef) => inputRef.current && inputRef.current.value;