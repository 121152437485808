import Dexie from 'dexie'

 const db = new Dexie("backup")

db.version(1).stores({
  data: "++id, firstName, middleNames, lastName, suffix, address, city, state, currentZip, dob, primaryPhone, secondaryPhone,    primaryEmail, secondaryEmail , employerName, profession, code, intake_method, referral_method, status, employmentProofType,    employmentProofFile, idProofFile, idProofType, selfCertSignature, uniqueEntrySignature, offline, signature, hf_subscribed,    offline_id"
})

 export const database = db.data



