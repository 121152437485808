export const expensesOptions = [
    { label: "Health care", value: "Health care" },
    { label: "Hospitalization", value: "Hospitalization" },
    { label: "Food for children", value: "Food for children" },
    { label: "Internet/technology", value: "Internet/technology" },
    { label: "Childcare", value: "Childcare" },
    { label: "Dependent care", value: "Dependent care" },
    { label: "Personal Protective Equipment (PPE)", value: "Personal Protective Equipment (PPE)" },
    { label: "Transportation", value: "Transportation" },
    { label: "Funeral/burial", value: "Funeral/burial" },
    { label: "Other", value: "Other" },
  ];