import imageCompression from 'browser-image-compression';
import axios from 'axios';

export function sanitizeID(id){
  if (typeof id === "string" && id.startsWith("id=")) {
    id = parseInt(id.slice(3));
  };
  return id;
};


export function findIndexById(objects, id) {
  id = sanitizeID(id);

  id = parseInt(id);
  
  for (let i = 0; i < Object.keys(objects).length; i++) {
    if (objects[i].id === id) {
      return i;
    };
  };
  return -1;
};


export function replaceNullFields(array) {
  for (let i = 0; i < array.length; i++) {
    let obj = array[i];
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === null) {
        obj[key] = "";
      };
    ;}
  };
  return array;
};

export const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());

export function sanitizeFields(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      if (!obj[key] || obj[key] === "none") {
        obj[key] = "-";
      }
      else if(key==='validation_response'){
        return obj[key];
      }
      else{
          obj[key] = titleCase(`${obj[key]}`);
      };
    }
  };
  return obj;
};

export async function convertImagesToBase64(imageArray) {
  let base64ImageArray = [];
  
  for (let i = 0; i < imageArray.length; i++) {
    const proxyProofFileRaw = imageArray[i];

    try {
      const compressedFile = await imageCompression(proxyProofFileRaw, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });

      const compressedFileReader = new FileReader();

      compressedFileReader.onloadend = () => {
        base64ImageArray.push(compressedFileReader.result);
      };

      compressedFileReader.readAsDataURL(compressedFile);
    } catch (error) {
      alert(error);
      throw error
    }
  }

  return new Promise(resolve => {
    let intervalId = setInterval(() => {
      if (base64ImageArray.length === imageArray.length) {
        clearInterval(intervalId);
        resolve(base64ImageArray);
      }
    }, 100);
  });
};




export function getTodaysDate() {
  var date = new Date();
  var year = date.getUTCFullYear();
  var month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  var day = date.getUTCDate().toString().padStart(2, '0');
  var hours = date.getUTCHours().toString().padStart(2, '0');
  var minutes = date.getUTCMinutes().toString().padStart(2, '0');
  var seconds = date.getUTCSeconds().toString().padStart(2, '0');
  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds + "Z";
};

export const getStats = async (params) => {

  const options = {
    url: `${process.env.REACT_APP_API_URL}api/users/digest`,
    method: "GET",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
      "Content-Type": "application/json",
    },
    params: {
      email: params.email,
      start_date: params.start_date,
      end_date: params.end_date
    }
  }
  return axios(options)
}
export   const getUTCDate = (newDate) => {
  const d = new Date(newDate);
  const utcDate = new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
  );
  return utcDate;
};