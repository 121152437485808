export const covidAttestation = {
    english: "By checking this box, and submitting this form I attest and Self-certify that I incurred expenses preparing for, preventing exposure to, and/or responding to the COVID-19 pandemic such as, but not limited to: costs for personal protective equipment (PPE), expenses associated with quarantines and testing, and dependent care.",
    spanish: "Al marcar esta casilla y enviar este formulario, atestiguo y autocertifico que incurrí en gastos para prepararme, prevenir la exposición y/o responder a la pandemia de COVID-19, tales como, entre otros: costos de equipo de protección personal (PPE), gastos asociados con cuarentenas y pruebas, y cuidado de dependientes."

}

export const uniqueEntryAttestation = {
    english: "By checking this box, and submiting this form I attest and Self-certify that I have not previously received a FFWR beneficiary payment through another entity.",
    spanish: "Al marcar esta casilla y enviar este formulario, doy fe y autocertifico que no he recibido previamente un pago de beneficiario de FFWR a través de otra entidad."
}

export const finalCert = {
    english: "By Signing and submitting I attest all information submitted to be true",
    spanish: "Al firmar y enviar, doy fe de que toda la información enviada es verdadera"
}