import React from "react";

const ErrorPage = () => {
  return (
    <>
      <h1>404 NOT FOUND</h1>
    </>
  );
};
export default ErrorPage;
