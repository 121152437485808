import { useContext, useEffect } from 'react';
import { FormContext } from '../Form';
import DatePicker from 'react-date-picker'

function DatePickerInput(props) {
  const {
    name,
    label,
    classNameLabel,
    className,
    value,
    formName,
  } = props;


  const { dispatch } = useContext(FormContext);

  // useEffect(() => {
  //   dispatch({
  //     type: 'UPDATE_FIELD',
  //     field: name,
  //     value: value,
  //     form: formName ?? 'form'
  //   });
  // }, [value, name, formName, dispatch])


  const handleDOBSubmit = (date, e) => {
    dispatch({
      type: 'UPDATE_FIELD',
      field: name,
      value: date,
      form: formName ?? 'form'

    });
  }

  // Date object to return date in UTC prevents date being behind by a day
  const getUTCDate = (newDate) => {
    const d = new Date(newDate);
    const utcDate = new Date(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
    );
    return utcDate;
  };
  
  return (
    <div>
      <label htmlFor={name} className={classNameLabel}>{label}</label>
      <DatePicker
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        className={className}
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date"
        onChange={handleDOBSubmit}
        value={ value && getUTCDate(value)}
        yearAriaLabel="Year"
        format='yyyy-M-d'
        yearPlaceholder='YYYY'
        monthPlaceholder='MM'
        dayPlaceholder='DD'
        disableCalendar={true}
        maxDate={new Date()}
      />
    </div>
  )
}

export default DatePickerInput;