import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from '../globalState/StateProvider';
import { useNavigate, Link } from 'react-router-dom';
import HfBanner from '../components/HfBanner';
import { logout } from '../services/UserServices';
import useNavigatorOnline from "use-navigator-online";
import { database } from '../globalState/DexieState';



function Dashboard() {
  const { isOnline, backOnline, backOffline } = useNavigatorOnline();
  const [offlineData, setOfflineData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);

  const getData = async () => {
    const data = await database.toArray()
    if (data.length > 0) {
      setOfflineData(data)
    }
  }
  const handleOnlineNavigation = ()=>{
    dispatch({ type: 'CLEAR_FORM', payload: {}});
    navigate('/new-applicant')

  }

  useEffect(() => {
    getData()
  }, [backOnline, backOffline, loading])

  const OfflineApplications = () => {
    navigate('/offline-applications-dashboard')
  }

  function handleLogOut() {
    logout().then((response) => {
      navigate('/login');
      dispatch({ type: 'SET_FORM', payload: { email: '', password: '' } })
    }).catch((error) => {
      console.error(error.message);
    });
  }

  return (
    <div className='wrapper'>
      <button type="button" className="btn btn-outline-danger logout" onClick={() => handleLogOut()} >Logout</button>
      <div className="flex">
        <span className="flex center-text">
          <h1 >
            USDA FFWR <br />
            Beneficiary Intake Application
          </h1>
        </span>
        <span className="flex center col-flex button-grp">
          <button type="button" className="btn btn-primary btn-lg dash-btn"  
           onClick={() => isOnline ? handleOnlineNavigation() : navigate('/offline') 
          }>New <br /> Applicant</button>
          <button type="button" className={`btn btn-secondary btn-lg dash-btn ${isOnline ? "" : "disabled"}`} onClick={() => navigate('/search')}>Update<br /> Application</button>
          {
            offlineData.length > 0 ?
              <div className=" d-flex  flex-column justify-content-center align-items-center  pt-4  w-100" >
                <p
                  className="offgrey fs-3">
                  {`You have ${offlineData.length} form${offlineData.length > 1 ? "s" : ""} waiting to be uploaded.`}
                </p>
                <button
                  className="btn btn-secondary btn-lg dash-btn m-0 p-2 w-50"
                  type="button"
                  onClick={() => {
                    OfflineApplications()
                  }}>
                  Offline Panel{offlineData.length > 1 ? "s" : ""}
                </button>
              </div> : ""
          }

        </span>
      </div>
      <div className="flex">
        <button className="stat-btn btn btn-secondary btn-sm w-25" onClick={() => navigate('/stats')}> My Daily Stats </button>
        <Link className="bug-link offgrey" to="/bug-reporting">Report Bug/Issue</Link>
      </div>
      <span className='version'>

      Version 1.6.0
      </span>
      <HfBanner />
    </div>
  );
}

export default Dashboard;
