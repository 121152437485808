import { useRef, useContext } from 'react';
import SignaturePad from 'react-signature-canvas'
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { store } from '../../globalState/StateProvider';
import { suffixOptions } from '../../constants/suffixOptions';
import { stateOptions } from '../../constants/stateOptions'
import { professionOptions } from '../../constants/professionOptions';
import { socNasicCodeOptions } from '../../constants/socNasicCodeOptions';
import { employmentProofOptions } from '../../constants/employmentProofOptions';
import { identityProofOptions } from '../../constants/identityProofOptions';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../components/BreadCrumbs';
import { breadCrumbLabels, formLabels, newApplicantHeadings, newAppMessages, signLabels, offlineAlerts } from '../../constants/formLabels';
import TranslationButtons from '../../components/TranslationButtons';
import { covidAttestation, uniqueEntryAttestation, finalCert } from '../../constants/attestations';
import { countryCodes } from '../../constants/countryCodeOptions';
import DatePicker from 'react-date-picker'
import { getUTCDate } from '../../services/Helpers';

export default function Offline({ data, handleChange, handleSubmit, requiredInputRefs, pageTitle, breadcrumbsArray }) {
  const { state } = useContext(store);
  const language = state.language
  const navigate = useNavigate();
    
  const signatureRef = useRef();
  const sigPad = signatureRef.current;

  // Components 

  function TextInput(
    label, 
    name,
    value,
    innerRef,
    errorMessage,
    placeholder = null,
    classNameInput = "form-control required",
    classNameLabel = "form-label isRequired",
    type = "text",

  ) {
    return (
      <div>
        <label htmlFor={name} className={classNameLabel}>{label}</label>
        <input
          type={type}
          className={classNameInput}
          name={name}
          id={name}
          value={value}
          ref={innerRef}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <div className="invalid-feedback">
          {errorMessage}
        </div>
      </div>

    )
  }

  function SelectInput(
    label,
    name,
    options = [],
    ref,
    value = "",
    className = "form-select required",
    labelClass = "form-label isRequired",
  ) {

    return (
      <div className="select">
        <label htmlFor={name} className={labelClass}>{label}</label>
        <select
          name={name}
          className={className}
          id={name}
          ref={ref}
          value={value}
          onChange={(e) => handleChange(e.target.name, e.target.value)} >
          <option value="">Select Type</option>
          {options.map(function (option) {
            return (<option key={option.value}
              value={option.value}>{option.value}</option>)
          })}
        </select>
      </div>
    )
  }
  
  function DatePickerInput(
    label,
    name,
    value,
    className = "form-select required",
    labelClass = "form-label isRequired",
  ) {

    return (
      <div className="select">
        <label htmlFor={name} className={labelClass}>{label}</label>
        <DatePicker
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        className={className}
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date"
        onChange={(date) => handleChange(name, date)} 
        value={ value && getUTCDate(value)}
        yearAriaLabel="Year"
        format='yyyy-M-d'
        yearPlaceholder='YYYY'
        monthPlaceholder='MM'
        dayPlaceholder='DD'
        disableCalendar={true}
        maxDate={new Date()}
        />
      </div>
    )
  }
  function CheckBox(
    label,
    name,
    val,
    ref
  ) {

    return (
      <div className="form-check">
        <input type="checkBox"
          className="form-check-input"
          name={name}
          id={name}
          ref={ref}
          checked={val}
          onChange={(e) => {
            handleChange(e.target.name, e.target.checked)
          }}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          {label}
        </label>
      </div>
    )
  }

  function FileUpload(
    label,
    name,
    ref,
    val
  ) {

    return (
      <>
        <div lang='es' className="upload-btn">
          <input
            type="file"
            label={label}
            className="file-upload form-control required"
            name={name}
            id={name}
            value={val}
            onChange={e => imageUpload(name, e)}
            ref={ref ?? null}
          />
          {data[name] ? <>
            <label htmlFor={name} className={'form-label'}>{"Selected File:"}</label>
            <img src={data[name]} alt={name} class="img-thumbnail" />
          </>
            : ""}
        </div>
      </>
    )
  }



  //functions

 

  const imageUpload = async (key, e) => {
    e.preventDefault()
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedImage = compressedFile;
      const imageReader = new FileReader();
      let imageBlob = new Blob([compressedImage], { type: compressedImage.type });
      imageReader.readAsDataURL(imageBlob);
      imageReader.onloadend = function () {
        let base64 = imageReader.result; // this is the base64 encoded string of the file
        handleChange(key, base64)
      }
    } catch (error) {
      alert(offlineAlerts.docUploadFail[language])
      e.target.value = null
      console.log(error.type, "Uploading document");
    }
  }

  const clear = (e) => {
    e.preventDefault()
    sigPad.clear()
    handleChange("signature", "")
  }
  const trim = (e) => {
    e.preventDefault()
    let url = sigPad && !sigPad.isEmpty() && sigPad.getTrimmedCanvas().toDataURL('image/png')
    handleChange("signature", url)
  }
  const SiggyComponents = () => {
    return (
      <div className={"sig-container"}>
        <div className={"sig"} style={{ display: data.signature ? 'none' : 'inline-block' }} >
          <SignaturePad canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            ref={signatureRef} />
        </div>

        {data.signature
          ? <div className='flex sig sig-container sig-saved'><img alt="saved-siggy" className={"sig-image sig-saved"}
            src={data.signature} />
            <span className='flex sig-saved '>
              {signLabels.signature[language]}
            </span>
          </div>
          : null}
        <br />
        <div>
          <button className={"siggy-buttons"} onClick={(e) => clear(e)}>
            {signLabels.clear[language]}
          </button>
          <button className={"siggy-buttons"} onClick={(e) => trim(e)}>
            {signLabels.save[language]}
          </button>
        </div>
        <p>{finalCert[language]}</p>
      </div>
    )
  }
  return (

    <div className="container-sm mb-5">
      <TranslationButtons />
      <h2 className="form-title">{pageTitle}</h2>
      <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      <form
        className="applicant-form"

      >
        <div>
          <h5>{newApplicantHeadings.personalInfo[language]}</h5>
          <hr style={{ margin: 0 }} />
          {/*delete this*/}
          {/* Name Section */}

          <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.firstName[language],
                "firstName",
                data.firstName,
                requiredInputRefs.firstName
              )}
              <div className="invalid-feedback">
                Please add a first name.
              </div>
            </div>

            <div className="col-6 mb-3">
              {TextInput(
                formLabels.middleNames[language],
                "middleNames",
                data.middleNames,
                null,
                null,
                null,
                "form-control",
                "form-label"
              )}
            </div>
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.lastName[language],
                "lastName",
                data.lastName,
                requiredInputRefs.lastName,
                null
              )}
            </div>
            <div className="col-6 mb-3">
              {SelectInput(
                formLabels.suffix[language],
                "suffix",
                suffixOptions,
                requiredInputRefs.suffix,
                data.suffix
              )}
            </div>
          </div>

          {/* End Name Section */}
          {/* Address Section */}

          <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.address[language],
                "address",
                data.address,
                null,
                null,
                null,
                "form-control",
                "form-label"
              )}
            </div>
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.city[language],
                "city",
                data.city,
                null,
                null,
                null,
                "form-control",
                "form-label")}
            </div>
            <div className="col-6 mb-3">
              {SelectInput(
                formLabels.state[language],
                "state",
                stateOptions,
                null,
                data.state,
                "form-select ",
                "form-label"
              )}
            </div>
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.currentZip[language],
                "currentZip",
                data.currentZip,
                requiredInputRefs.currentZip,
                newAppMessages.invalidZip[language],
                newAppMessages.zipPlaceHolder[language]
              )}
            </div>
            <div className="col-6 mb-3">
              {DatePickerInput(                
                formLabels.dob[language],
                "dob",
                data.dob,
                "YYYY-MM-DD"
                )}
            </div>
          </div>
          {/* End Address Section */}
          {/* Profession Section */}

          <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              {TextInput(
                formLabels.employerName[language],
                "employerName",
                data.employerName,
                requiredInputRefs.employer
              )}
            </div>
            <div className="col-6 mb-3">
              {SelectInput(
                formLabels.profession[language],
                "profession",
                professionOptions,
                requiredInputRefs.profession,
                data.profession
              )}
            </div>
            <div className="col-6 mb-3">
              {SelectInput(
                formLabels.code[language],
                "code",
                socNasicCodeOptions(data.profession),
                requiredInputRefs.code,
                data.code
              )}
            </div>
          </div>
          {/* End Profession Section*/}
          {/* Contact Section */}
          <div className="row align-items-start mb-5">
            <h5>{newApplicantHeadings.contactInfo[language]}</h5>
            <label className="isRequired mb-1">{newAppMessages.contactIsRequired[language]}</label>
            <hr style={{ margin: 0 }} />

            <div className="col-6 mb-3">
              <div className='col-8 mb-3'>

                {SelectInput(
                  formLabels.primary_country_code[language],
                  "primary_country_code",
                  countryCodes,
                  null,
                  data.primary_country_code,
                  "form-select ",
                  "form-label",
                )}
              </div>
              {TextInput(
                formLabels.primaryPhone[language],
                "primaryPhone",
                data.primaryPhone,
                null,
                null,
                newAppMessages.phonePlaceHolder[language],
                "form-control",
                "form-label",
                "tel")}
              {CheckBox(
                formLabels.primary_can_text[language],
                null
              )}
            </div>
            <div className="col-6 mb-3">
              <div className='col-8 mb-3'>
                {SelectInput(
                  formLabels.secondary_country_code[language],
                  "secondary_country_code",
                  countryCodes,
                  null,
                  data.secondary_country_code,
                  "form-select ",
                  "form-label",
                )}
              </div>
              {TextInput(
                formLabels.secondaryPhone[language],
                "secondaryPhone",
                data.secondaryPhone,
                null,
                null,
                newAppMessages.phonePlaceHolder[language],
                "form-control",
                "form-label",
                "tel")}
              {CheckBox(
                formLabels.secondary_can_text[language],
                null
              )}
            </div>
            <div className="col-6 mb-3">
              <>
                {TextInput(
                  formLabels.primaryEmail[language],
                  "primaryEmail",
                  data.primaryEmail,
                  null,
                  newAppMessages.invalidEmail[language],
                  "email@example.com",
                  "form-control",
                  "form-label",
                  "email")}
              </>
            </div>
            <div className="col-6 mb-3">
              <>
                {TextInput(
                  formLabels.secondaryEmail[language],
                  "secondaryEmail",
                  data.secondaryEmail,
                  null,
                  null,
                  "email@example.com",
                  "form-control",
                  "form-label",
                  "email")}
              </>
            </div>
          </div>
          {/* End Contact Section */}
          {/* Documents Section */}
          <div className="row align-items-start mb-5">
            <h5>Supporting Documents</h5>
            <hr style={{ margin: 0 }} />
            <div className="row align-items-start">
              <div className="col-5 mb-3">
                {SelectInput(
                  formLabels.idProof[language],
                  "idProofType",
                  identityProofOptions,
                  requiredInputRefs.idProofType,
                  data.idProofType
                )}
              </div>
              <div className="col-7 mb-3">
                {FileUpload(
                  formLabels.idProof[language],
                  "idProofFile",
                  requiredInputRefs.idProofFile
                )}
              </div>
            </div>
            <div className="row align-items-start">
              <div className="col-5 mb-3">
                {SelectInput(
                  formLabels.employmentProof[language],
                  "employmentProofType",
                  employmentProofOptions,
                  requiredInputRefs.employmentProofType,
                  data.employmentProofType
                )}
              </div>
              <div className="col-7 mb-3">
                {FileUpload(
                  formLabels.employmentProof[language],
                  "employmentProofFile",
                  requiredInputRefs.employmentProofFile,
                  
                )}

              </div>
            </div>
          </div>
          {/* EndDocuments Section */}

          {/* Check box section */}
          <div className="row align-items-start mb-5">
            <div className="col-12 mb-3">
              {CheckBox(
                formLabels.hf_subscribed[language],
                "hf_subscribed",
                data.hf_subscribed,
                null
              )}
            </div>
            <div className="col-12 mb-3">
              {CheckBox(
                formLabels.offline[language],
                "offline",
                data.offline,
                requiredInputRefs.offline
              )}
            </div>
            <div className="col-12 mb-3">
              {CheckBox(
                covidAttestation[language],
                "selfCertSignature",
                data.selfCertSignature,
                requiredInputRefs.selfCertSignature
              )}
            </div>
            <div className="col-12 mb-3">
              {CheckBox(
                uniqueEntryAttestation[language],
                "uniqueEntrySignature",
                data.uniqueEntrySignature, 
                requiredInputRefs.uniqueEntrySignature
              )}
            </div>
          </div>

         

          {/* End Check box section */}
          {SiggyComponents()}
          <button
            type="button"
            className="form-button flex center"
            onClick={() => handleSubmit()}>
            {formLabels.save[language]}
          </button>
          {pageTitle.toLowerCase().includes('edit') 
          && <button
            type="button"
            className="form-button-cancel flex center"
              onClick={() => navigate('/offline-applications-dashboard')}>
              {formLabels.cancel[language]}
            </button>}
        </div>
      </form>
    </div>
  );
}

