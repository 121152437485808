import { useContext } from 'react';
import { store } from '../../globalState/StateProvider';
import { validateInput } from '../../services/FormValidation';

function TextArea(props) {
  const {
    label,
    name,
    className,
    description,
    placeholder,
    formName
  } = props;

  const { state, dispatch } = useContext(store);


  const handleChange = async e => {
    validateInput(e);
    await dispatch({
      type: 'UPDATE_FIELD',
      field: e.target.name,
      value: e.target.value,
      form: formName ?? 'form'
    });
  };

  let formValues = { ...state[formName] };

  return (
    <div>
      <label htmlFor={name} className="form-label">{label}</label>
      <p className="textarea-description">{description}</p>
      <div className="form-floating">
        <textarea
          className={className}
          name={name}
          id={name}
          placeholder={placeholder}
          value={formValues[name]}
          onChange={handleChange}></textarea>
      </div>
    </div>
  )
}

export default TextArea;