export const socFarmWorkerField = [
    { label: '45-2041 (Graders and Sorters, Agricultural Products)', value: '45-2041 (Graders and Sorters, Agricultural Products)' },
    { label: '45-2091 (Agricultural Equipment Operators)', value: '45-2091 (Agricultural Equipment Operators)' },
    { label: '45-2092 (Farmworkers and Laborers, Crop, Nursery and Greenhouse)', value: '45-2092 (Farmworkers and Laborers, Crop, Nursery and Greenhouse)' },
    { label: '45-2099 (Agricultural Workers, All Other)', value: '45-2099 (Agricultural Workers, All Other)' },
    { label: '53-7064 (Packers and Packagers, Hand)', value: '53-7064 (Packers and Packagers, Hand)' },
];

export const socFarmWorkerLiveStock = [
    { label: '45-2041 (Graders and Sorters, Agricultural Products)', value: '45-2041 (Graders and Sorters, Agricultural Products)' },
    { label: '45-2093 (Farmworkers, Farm, Ranch, and Aquacultural Animals)', value: '45-2093 (Farmworkers, Farm, Ranch, and Aquacultural Animals)' },
    { label: '45-2099 (Agricultural Workers, All Other)', value: '45-2099 (Agricultural Workers, All Other)' },
    { label: '53-7064 (Packers and Packagers, Hand)', value: '53-7064 (Packers and Packagers, Hand)' },
];


export const socMeatpacker = [
    { label: '51-3023 (Slaughterers and Meat Packers)', value: '51-3023 (Slaughterers and Meat Packers)' },
    { label: '51-3022 (Meat, Poultry, and Fish Cutters and Trimmers)', value: '51-3022 (Meat, Poultry, and Fish Cutters and Trimmers)' },
    { label: '51-3099 (Food Processing Workers, All Other)', value: '51-3099 (Food Processing Workers, All Other)' },
];


export const socNasicCodeOptions = ( profession ) =>{
    switch(profession){
        case 'Farmworker (Field)':
            return socFarmWorkerField
        case'Farmworker (Livestock)':
            return socFarmWorkerLiveStock
        case'Meatpacker':
            return socMeatpacker
        default:
            return undefined;
    }
}