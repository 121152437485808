import React, { useState, useRef } from 'react'
import { Modal } from 'bootstrap';
import { employmentProofOptions } from '../../constants/employmentProofOptions';
import { identityProofOptions } from '../../constants/identityProofOptions';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { FiCheckSquare } from 'react-icons/fi'


export default function Index({ compId, reportId }) {
  const [loading, setLoading] = useState(false)
  const [success, setSucess] = useState(false)
  const [docs, setDocs] = useState({
    doc1: {
      docType: "",
      image: ""
    },
    doc2: {
      docType: "",
      image: ""
    },
    doc3: {
      docType: "",
      image: ""
    }
  })

  const [docNum, setDocNum] = useState(1)
  const imageRefs = {
    image1: useRef(),
    image2: useRef(),
    image3: useRef()
  }

  const handleAddDoc = () => setDocNum(prev => prev + 1)

  //clears the state if the modal is cancelled.
  const handleCancel = () => {
    setDocs({
      doc1: {
        docType: "",
        image: ""
      },
      doc2: {
        docType: "",
        image: ""
      },
      doc3: {
        docType: "",
        image: ""
      }
    })
    setDocNum(1)
    setSucess(false)
  }

  //changes the doctype inside doc object.
  const handleDocTypeChange = (setObj, obj, doc, e) => {
    e.preventDefault();
    setObj({ ...obj, [doc]: { ...obj[doc], docType: e.target.value } })
  }


  const handleImageUpload = (setObj, obj, doc, value) => {
    setObj({ ...obj, [doc]: { ...obj[doc], image: value } })
  }


  const SelectInput = ({ value, setObj, obj, doc, }) => {
    return (
      <>
        <label htmlFor="options" className="form-label isRequired">Document Type</label>
        <select
          name="options"
          className="form-select required"
          id="options"
          value={value}
          onChange={(e) => handleDocTypeChange(setObj, obj, doc, e)} >
          <option value="">Select Type</option>
          {employmentProofOptions.map(function (option) {
            return (
              <option key={option.value}
                value={option.value}>{option.value}
              </option>)
          })}
          {identityProofOptions.map(function (option) {
            return (
              <option key={option.value}
                value={option.value}>{option.value}
              </option>)
          })
          }
          <option value="Other">Other
          </option>
        </select>
      </>
    )
  }

  const FileUpload = (
    name,
    label,
    setObj,
    obj,
    doc,
    ref
  ) => {
    return (
      <div className="upload-btn">
        <input
          type="file"
          label={label}
          className="file-upload form-control required"
          name={name}
          id={name}
          onChange={event => imageUpload(setObj, obj, doc, event)}
          ref={ref ?? null}
        />
      </div>
    )
  }
  const imageUpload = async (setObj, obj, doc, e) => {
    e.preventDefault()
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedImage = compressedFile;
      const imageReader = new FileReader();
      let imageBlob = new Blob([compressedImage], { type: compressedImage.type });
      imageReader.readAsDataURL(imageBlob);
      imageReader.onloadend = function () {
        let base64 = imageReader.result; // this is the base64 encoded string of the file
        handleImageUpload(setObj, obj, doc, base64)
      }
    } catch (error) {
      alert("Could not upload the document. Please try again later or upload a different document.")
      e.target.value = null
      console.log(error.type, "uploading document");
    }
  }

  //creates post request for axios
  const postImage = async (id, obj) => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}api/requests/${id}/supporting_docs`,
      method: "POST",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
        "Content-Type": "application/json"
      },
      data: {
       
          doc_type: obj.docType,
          image: obj.image
       
      }
    }
    return await axios(options)
  }

  const handleSubmitFiles = async () => {
    setLoading(true)
    const supportingdocs = Object.values(docs).map(rec => (rec.docType !== "" && rec.image !== "") ? postImage(reportId, rec) : null)
    try {
      await axios.all([...supportingdocs])
        .then((responses) => {
          responses.forEach(resp => {
            console.log("status: ", resp)
            setSucess(true)
            setLoading(false)
          })
        })
        .catch(error => {
          console.error("error occured", error);
          setLoading(false)
        });
    }
    catch (error) { console.log(error)
    alert("Failed to upload file") }
  }




  return (
    <div
      className="modal fade"
      id={compId}
      tabIndex="-1"
      aria-labelledby="addDocModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-title fs-5" id="addDocModalLabel">Add Supporting Documents</span>
          </div>

          {loading ?
            <div className="m-auto my-5">
              <ClipLoader color="#36d7b7" cssOverride={{
                margin: 'auto',
                justifyItems: "center"
              }} />
            </div> :
            success ?
              <div className="m-auto my-5">
                <FiCheckSquare />
                <span>
                  {" "}Your image(s) have been added successfully!
                </span>
              </div> :
              <>
                <div className="modal-body">
                  <div className="select">
                    <SelectInput
                      value={docs.doc1.docType}
                      setObj={setDocs}
                      obj={docs}
                      doc={"doc1"}
                    />
                  </div>

                  {FileUpload(
                    "additionalDoc1File",
                    "Additional Document",
                    setDocs,
                    docs,
                    "doc1",
                    imageRefs.image1
                  )}

                  {
                    docNum > 1 &&
                    <>
                      <div className="select">
                        <SelectInput
                          value={docs.doc2.docType}
                          setObj={setDocs}
                          obj={docs}
                          doc={"doc2"}
                          title={"additionalDoc2Type"}
                        />
                      </div>
                      {FileUpload(
                        "additionalDoc2File",
                        "Additional Document",
                        setDocs,
                        docs,
                        "doc2",
                        imageRefs.image2
                      )}
                    </>}
                  {
                    docNum > 2 &&
                    <>
                      <div className="select">
                        <SelectInput
                          value={docs.doc3.docType}
                          setObj={setDocs}
                          obj={docs}
                          doc={"doc3"}
                          title={"additionalDoc3Type"}
                        />
                      </div>
                      {FileUpload(
                        "additionalDoc3File",
                        "Additional Document",
                        setDocs,
                        docs,
                        "doc3",
                        imageRefs.image3
                      )}
                    </>}
                </div>
                {docNum <= 2 && <button type="button" className='btn btn-warning m-auto mb-4 col-6' onClick={handleAddDoc}>Additional Documents</button>}
              </>}
          <div className="modal-footer">
            {success ?
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCancel}> Close </button> :
              <>
                <button type="button" className="btn btn-primary" onClick={() => handleSubmitFiles()} disabled={loading}> Save changes </button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCancel} disabled={loading}> Cancel </button>
              </>}
          </div>
        </div>
      </div>
    </div>
  )
}
