import { NavLink } from 'react-router-dom';

const Breadcrumbs = ({ breadcrumbs }) => (
    <ol className="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <li className="crumb" key={breadcrumb.key}>
          <NavLink to={breadcrumb.url}>
            {breadcrumb.text} 
          </NavLink>
            {breadcrumbs.length-1 !== index && ' >'}
        </li>
      ))}
    </ol>
  );
  export default Breadcrumbs;