
import { useContext } from 'react';
import { store } from '../../globalState/StateProvider'

function TranslationButtons() {
    const { state, dispatch } = useContext(store);
    const {language} = state
    //   const { form, handleFormChange } = formContext;
    
    return (
        <div className='translate-group'>
        <span 
          className={language === "english" ? 'translate-group-active':""} 
          onClick={() => dispatch({type: 'SET_LANGUAGE', payload: 'english'})}>
          English
        </span>/
        <span 
          className={language === "spanish" ? 'translate-group-active' : ""} 
          onClick={() => dispatch({type: 'SET_LANGUAGE', payload: 'spanish'})}>
          Spanish
        </span>
      </div>
    )
}

export default TranslationButtons;
