import axios from "axios";
import { getTodaysDate } from '../services/Helpers';

const baseURL = `${process.env.REACT_APP_API_URL}api/`;
const per_page = 15;
// const authHeader = {
//     Authorization : `Bearer ${localStorage.getItem('authToken')}`
//   }
export function handleError(response){
    if(response.status === 401){
        //TO-DO: Update this as we return 401 when users try to update a paid request 
 //In that case we don't want the logout to happen
        localStorage.clear();
        // localStorage.clear();
        // console.log("ERROR, res", response)
    }
    return  response.data
}

export async function login(data) {

    return await axios
        .post(baseURL + 'users/sign_in', { 
            email: data.email, 
            password: data.password })
        .then((response) => {

            //Should probably handle response status somewhere in here //dont remember how axios handles it 
            localStorage.setItem('authToken', response.headers['access-token'])
            return response;
        })
        .catch(error => {
            throw handleError(error.response);
        });
};
export async function logout() {

    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
        }});

    return await reqInstance
        .delete(baseURL + 'users/sign_out', {})
        .then((response) => {
            localStorage.clear();
            return response;
        })
        .catch(error => {
            throw handleError(error.response);
        });
};

export async function resetPassword(data) {
    
    return await axios
        .post(baseURL + 'users/reset-password', { 
            email: data.email
        })
        .then((response) => {
            return response;
        })
        .catch(error => {
            throw handleError(error.response);
        });
};


export async function postAppInfo(form) {
    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
        }});
    
    const submissionDate = getTodaysDate();
    
    const createRequest = {
        proof_of_identity: form.idProofType,
        proof_of_employment: form.employmentProofType,
        employmentProofFile: form.employmentProofFile,
        idProofFile: form.idProofFile,
        date_of_submission: submissionDate,
        first_name: form.firstName,
        middle_name: form.middleNames,
        last_name: form.lastName,
        suffix: form.suffix,
        street_or_PO_box: form.address,
        city: form.city,
        state: form.state === '' ? null : form.state,
        zip_code: form.currentZip.trim(),
        date_of_birth: form.dob,
        primary_country_code: form.primary_country_code === '' ? null: form.primary_country_code,
        primary_phone: form.primaryPhone === '' ? null: form.primaryPhone,
        primary_can_text: form.primary_can_text, 
        secondary_phone: form.secondaryPhone ==='' ? null : form.secondaryPhone,
        secondary_country_code: form.secondary_country_code ==='' ? null : form.secondary_country_code,
        secondary_can_text: form.secondary_can_text,
        primary_email: form.primaryEmail === '' ? null : form.primaryEmail,
        secondary_email: form.secondaryEmail === '' ? null : form.secondaryEmail,
        employer_name: form.employerName,
        profession: form.profession,
        code: form.code,
        gender: form.gender,
        family_size: form.houseHold,
        related_expenses: form.related_expenses.join(","),
        // BEGIN This field was hardcoded for testing purposes
        intake_method: form.intake_method ==='' ? "In-Person - tablet/mobile device": form.intake_method,
        referral_method: form.referral_method === '' ? "None" : form.referral_method,
        status: 0,
        // END 
        //TO_DO These are not updating in the state before submission causing an error due to missing fields
        hf_subscribed: form.hf_subscribed,
        card_proxy: form.cc,
        bank_name: form.bankName,
        ach_routing: form.routingNumber,
        ach_account: form.accountNumber,
        notes: form.notes,
        signatureFile: form.signatureFile,
        
        // covid_attestation: form.covid_attestation,
        // unique_entry_attestation: form.unique_entry_attestation,
    };

    return await reqInstance
    .post(baseURL + 'requests/', createRequest)            
    .then((response) => {

        //Should probably handle response status somewhere in here //dont remember how axios handles it 
        return response
    })
    .catch(error => {
            throw handleError(error.response);
    });
}


export async function postSupportingDocs(params, ID) {
    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
        }});
    
    return await reqInstance
    .post(baseURL + `requests/${ID}/supporting_docs`, params)            
    .then((response) => {
        return response
    })
    .catch(error => {
            throw handleError(error.response);
    });
}

export async function updateAppInfo(userId, form) {
    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
        }});
    
    const submissionDate = getTodaysDate();
    const patchUser = {
        date_of_submission: submissionDate,
        first_name: form.firstName,
        middle_name: form.middleNames,
        last_name: form.lastName,
        suffix: form.suffix,
        street_or_PO_box: form.address,
        city: form.city,
        state: form.state === '' ? null : form.state,
        zip_code: form.currentZip.trim(),
        date_of_birth: form.dob,
        primary_country_code: form.primary_country_code === '' ? null: form.primary_country_code,
        primary_phone: form.primaryPhone === '' ? null: form.primaryPhone,
        secondary_country_code: form.secondary_country_code ==='' ? null : form.secondary_country_code,
        secondary_phone: form.secondaryPhone ==='' ? null : form.secondaryPhone,
        primary_email: form.primaryEmail === '' ? null : form.primaryEmail,
        secondary_email: form.secondaryEmail === '' ? null : form.secondaryEmail,
        employer_name: form.employerName,
        profession: form.profession,
        code: form.code,
        gender: form.gender,
        family_size: form.houseHold,
        related_expenses: form.related_expenses ? form.related_expenses.join(",") : form.related_expenses,
        // BEGIN This field was hardcoded for testing purposes
        intake_method: form.intake_method ==='' ? "Phone": form.intake_method,
        // END 
        referral_method: form.referral_method === '' ? "None" : form.referral_method,
        notes: form.notes,


    };

    return await reqInstance
    .patch(baseURL + 'requests/' + userId, patchUser)            
    .then((response) => {

        //Should probably handle response status somewhere in here //dont remember how axios handles it 
        return response
    })
    .catch(error => {
            throw handleError(error.response);
    });
}


export async function addPaymentInfo(userId, form) {
    
    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
        }});
    
    const params = {
        card_proxy: form.cc === '' ? null: form.cc,
        bank_name: form.bankName === '' ? null: form.bankName,
        ach_routing: form.routingNumber === '' ? null: form.routingNumber,
        ach_account: form.accountNumber === '' ? null: form.accountNumber
    };

    return await reqInstance
    .patch(baseURL + 'requests/' + userId, params)            
    .then((response) => {

        //Should probably handle response status somewhere in here //dont remember how axios handles it 
        return response
    })
    .catch(error => {
            throw handleError(error.response || error.error);
    });
};

//fetch beneficiary
export async function fetchBeneficiary(data) {

    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
          }});

    return await reqInstance
        .get(baseURL + `requests/${data.id}`)
        .then((response) => {

            //Should probably handle response status somewhere in here //dont remember how axios handles it 
            return response;
        })
        .catch(error => {
                throw handleError(error.response);
        });
};

export async function search(data) {

    let reqInstance = axios.create({
        headers: {
            Authorization : `Bearer ${localStorage.getItem('authToken')}`
          }});

    return await reqInstance
        .get(baseURL + 'requests/', {
            params: {
                id: data.id,
                aeyon_id: data.aeyonId,
                name: data.firstName,
                zip_code: data.currentZip,
                date_of_birth: data.dob,
                primary_phone: data.primaryPhone,
                primary_email: data.primaryEmail,
                per_page: data.perPage ?? per_page ,
                page: data.page ?? 1,
                sort: data.sort ?? 'date_of_submission',
                direction: data.direction ?? 'desc',
              }
        })
        .then((response) => {

            //Should probably handle response status somewhere in here //dont remember how axios handles it 
            return response;
        })
        .catch(error => {
                throw handleError(error.response);
        });
};