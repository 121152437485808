import ReactPaginate from "react-paginate";

function Pagination(props) {
    const {
        total,
        perPage,
        handlePageChange,
        currentPage
    } = props;
    const PageCount = Math.ceil(total / perPage);
    const pageIndex = currentPage - 1;
    return total > perPage ? (
        <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={PageCount}
            marginPagesDisplayed={4}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            // initialPage={1}
            forcePage={pageIndex}
        />
    ) : (<></>)
}

export default Pagination;
