export const formLabels = {
    firstName: {
        english: "First Name",
        spanish: "Nombre"
    },
    middleNames: {
        english: "Middle Name",
        spanish: "Segundo Nombre"
    },
    lastName: {
        english: "Last Name(s)",
        spanish: "Apellido(s)"
    },
    suffix: {
        english: "Suffix",
        spanish: "Sufijo"
    },
    address: {
        english: "Address",
        spanish: "Dirección"
    },
    city: {
        english: "City",
        spanish: "Ciudad"
    },
    state: {
        english: "State",
        spanish: "Estado"
    },
    currentZip: {
        english: "Current Zipcode",
        spanish: "Código Postal"
    },
    dob: {
        english: "Date of Birth",
        spanish: "Fecha de Nacimiento"
    },
    gender: {
        english: "Gender",
        spanish: "Género"
    },
    houseHold: {
        english: "Usual number of family members who live in the same household or depend on the applicant for support, INCLUDING the applicant?",
        spanish: "Número de miembros de la familia que viven en el mismo hogar o dependen del solicitante para recibir apoyo, INCLUYENDO al solicitante?"
    },
    expenses: {
        english: "What extra expenses related to the pandemic has it been most difficult for you and your family to meet? (Choose not more than 4)",
        spanish: "¿Qué gastos extras relacionados con la pandemia han sido más difíciles de cubrir para ti y tu familia? (Elija no más de 4)"
    },
    employerName: {
        english: "Employer Name",
        spanish: "Nombre de Empleador"
    },
    profession: {
        english: "Profession",
        spanish: "Profesión"
    },
    code: {
        english: "SOC/NAICS Code",
        spanish: "Codigó SOC/NAICS"
    },
    Referral: {
        english: "Referral Method",
        spanish: "Modo de referencia"
    },
    primary_country_code: {
        english: "Primary Country Code",
        spanish: "Código de País Primario"
    },
    primaryPhone: {
        english: "Primary Phone Number",
        spanish: "Numero de Telefono Primario"
    },
    primary_can_text: {
        english: "If eligible for payment, Hispanic Federation will notify you via text message that your card is activated. By checking this box, I agree to receive SMS updates from Hispanic Federation.",
        spanish: "Si es elegible para el pago, Hispanic Federation le notificará a través de un mensaje de texto que su tarjeta está activada. Al marcar esta casilla, acepto recibir actualizaciones por SMS de Hispanic Federation"
    },
    secondary_country_code: {
        english: "Secondary Country Code",
        spanish: "Código de País Secundario"
    },
    secondaryPhone: {
        english: "Secondary Phone Number",
        spanish: "Numero de Telefono Secundario"
    },
    secondary_can_text: {
        english: "If eligible for payment, Hispanic Federation will notify you via text message that your card is activated. By checking this box, I agree to receive SMS updates from Hispanic Federation.",
        spanish: "Si es elegible para el pago, Hispanic Federation le notificará a través de un mensaje de texto que su tarjeta está activada. Al marcar esta casilla, acepto recibir actualizaciones por SMS de Hispanic Federation"
    },
    primaryEmail: {
        english: "Primary Email",
        spanish: "Correo Electrónico Primario"
    },
    secondaryEmail: {
        english: "Secondary Email",
        spanish: "Correo Electrónico Secundario"
    },
    hf_subscribed: {
        english: "I am interested in receiving additional information from Hispanic Federation about other programs and benefits that I might be eligible for.",
        spanish: "Estoy interesado/interesada en recibir información adicional de Hispanic Federation sobre otros programas y beneficios para los que podría ser elegible."
    },
    offline: {
        english: "I understand that this website saves the data offline on the device and may automatically post it to the servers once a connection is established. By using this application, you consent to the collection and use of user data in this manner. Please note that we take the privacy of our users seriously and take appropriate measures to protect user data.",
        spanish: "Entiendo que este sitio web guarda los datos fuera de línea en el dispositivo y puede publicarlos automáticamente en los servidores una vez que se establece una conexión. Al usar esta aplicación, usted acepta la recopilación y el uso de datos de usuario de esta manera. Tenga en cuenta que nos tomamos muy en serio la privacidad de nuestros usuarios y tomamos las medidas adecuadas para proteger los datos de los usuarios."
    },
    idProof: {
        english: "Proof of Identification",
        spanish: "Prueba de Identificación"
    },
    employmentProof: {
        english: "Proof of Employment",
        spanish: "Prueba de Empleo"
    },
    notes: {
        english: "Notes",
        spanish: "Notas"
    },
    save: {
        english: "Save form",
        spanish: "Guardar formulario"
    },
    cancel:{
        english: "Discard Changes",
        spanish: "Descartar los cambios"
    }
}
export const signLabels = {
    save: {
        english: "Save",
        spanish: "Guardar"
    },
    clear: {
        english: "Clear",
        spanish: "Claro"
    },
    signature: {
        english: "Signature Saved",
        spanish: "Firma Guardada"
    }
}
export const offlineAlerts = {
    applicationSaved: {
        english: "Application saved",
        spanish: "Solicitud guardada"
    },
    fillRequiredFields: {
        english: "Please fill in all required fields. Required fields are marked with a red asterisk.",
        spanish: "Por favor, rellene todos los campos obligatorios. Los campos obligatorios están marcados con un asterisco rojo."
    },
    docUploadFail: {
        english: "Could not upload the document. Please try again later or upload a different document.",
        spanish: "No se pudo cargar el documento. Vuelva a intentarlo más tarde o cargue otro documento."
    }
}

export const offlineToastText ={
    online:{
        english:"You are online!",
        spanish:"¡Estás en línea!",
    },
    offline: {
        english:"You are offline!",
        spanish:"¡Estas desconectado!"
    }
}
export const newApplicantHeadings = {
    newApplicant: {
        english: "New Applicant",
        spanish: "Solicitacion Nueva"
    },
    personalInfo: {
        english: "Personal Info",
        spanish: "Datos Personales"
    },
    contactInfo: {
        english: "Contact Info",
        spanish: "Datos de Contacto"
    },
    proofs: {
        english: "Proofs",
        spanish: "Pruebas"
    },
    attestations: {
        english: "Attestations",
        spanish: "Certificaciones"
    },
    offLineApplication: {
        english: "Offline Application",
        spanish: "Aplicación fuera de línea"
    },
    editApplication:{
        english: "Edit Offline Application",
        spanish: "Editar aplicación sin conexión"
    }
}
export const newAppMessages = {
    invalidFirstName: {
        english: "First name is required",
        spanish: "El nombre es requerido"
    },
    invalidLastName: {
        english: "Last name is required",
        spanish: "El apellido es requerido"
    },
    invalidSuffix: {
        english: "Suffix is required",
        spanish: "Sufijo es requerido"
    },
    invalidEmployer: {
        english: "Employer name is required",
        spanish: "El nombre del empleador es requerido"
    },
    invalidCode: {
        english: "Code is required",
        spanish: "Codigo es requerido"
    },
    invalidProfession: {
        english: "Profession is required",
        spanish: "Profesion es requerido"
    },
    invalidZip: {
        english: "Zipcode must be 5 digits",
        spanish: "Código postal debe tener 5 dígitos"
    },
    invalidPhone: {
        english: "Phone must be 10 digits",
        spanish: "Numero debe tener 10 dígitos"
    },
    invalidEmail: {
        english: "Please enter a valid email",
        spanish: "Porfavor ingrese un correo valido"
    },
    invalidContact: {
        english: "Please enter a phone number or email",
        spanish: "Por favor ingrese un numero de telefono o correo electrónico "
    },
    invalidFields: {
        english: "Uh-oh, please review entries",
        spanish: "Uh-oh, porfavor revise sus entradas"
    },
    contactIsRequired: {
        english: "Either phone or email is required",
        spanish: "Un numero de telefono o correo electrónico es requerido"
    },
    zipPlaceHolder: {
        english: "5 digit Format",
        spanish: "Formato de 5 dígitos"
    },
    phonePlaceHolder: {
        english: "10-digits (4408675309)",
        spanish: "10 dígitos (4408675309)"
    }

}

export const breadCrumbLabels = {
    dashboard: {
        english: "Dashboard",
        spanish: "Panel"
    },
    newApplicant: {
        english: "New Applicant",
        spanish: "Solicitacion Nueva"
    },
    proofsAndAttestations: {
        english: "Proofs & Attestations",
        spanish: "Pruebas y Certificaciones"
    },
    offLineApplication: {
        english: "Offline Application",
        spanish: "Aplicación fuera de línea"
    }

}