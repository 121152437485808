import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useEffect, useRef } from 'react';
import Form from '../components/Form';
import SelectInput from '../components/SelectInput';
import MultiSelectInput from '../components/MultiSelectInput';
import DatePickerInput from '../components/DatePickerInput';
import TextInput from '../components/TextInput';
import CheckBox from '../components/CheckBox';
import { suffixOptions } from '../constants/suffixOptions';
import { genderOptions } from '../constants/genderOptions';
import { houseHoldOptions } from '../constants/houseHoldOptions';
import { expensesOptions } from '../constants/expensesOptions';
import { stateOptions } from '../constants/stateOptions'
import { professionOptions } from '../constants/professionOptions';
import { socNasicCodeOptions } from '../constants/socNasicCodeOptions';
import { store } from '../globalState/StateProvider';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../components/BreadCrumbs';
import { checkInputRefValue, validateInput, isUSAZipCode } from '../services/FormValidation';
import { countryCodes } from '../constants/countryCodeOptions';
import { breadCrumbLabels, formLabels, newApplicantHeadings, newAppMessages } from '../constants/formLabels';
import TranslationButtons from '../components/TranslationButtons';

function NewApp() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);

  const language = state.language

  const requiredInputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    suffix: useRef(),
    employer: useRef(),
    profession: useRef(),
    code: useRef(),
    currentZip: useRef()
  }
  const demoGraphicInputRefs = {
    gender: useRef(),
    houseHold: useRef(),
    expenses: useRef(),
  }
  
  
  const contactInputRefs={
    phone: useRef(),
    email: useRef(),
    
  }


  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const clear = searchParams.get("clear");

  // If hitting this page from breadcrubs array was no easy way to clear our state, so used url params 
  useEffect(() => {
    clear && dispatch({ type: 'CLEAR_FORM', payload: {} });
  }, [paramsString])

  const handleSubmit = async(form)=>{
    for (const [key, value] of Object.entries(requiredInputRefs)) {
      validateInput(value, key)
    }

    if (!contactInputRefs.email.current.value && !contactInputRefs.phone.current.value) {
      alert(newAppMessages.invalidContact[language]);
      return false;
    };

    // update global form state
    dispatch({ type: 'SET_FORM', payload: {...form}});


    const name = checkInputRefValue(requiredInputRefs.firstName) && checkInputRefValue(requiredInputRefs.lastName) && checkInputRefValue(requiredInputRefs.suffix);
    const contact = checkInputRefValue(contactInputRefs.phone) || checkInputRefValue(contactInputRefs.email);
    
    if (name && isUSAZipCode(form.currentZip) && checkInputRefValue(requiredInputRefs.currentZip)  && checkInputRefValue(requiredInputRefs.employer) &&
state.form.dob && checkInputRefValue(requiredInputRefs.profession) && checkInputRefValue(requiredInputRefs.code) 
    && contact) {
        navigate('/new-applicant-cont');
    }else{
      alert(newAppMessages.invalidFields[language]);
    };
    
  };
  const breadcrumbsArray =[{key: 'key-1', text: breadCrumbLabels.dashboard[language], url: '/dashboard' }, {key: 'key-2', text: breadCrumbLabels.newApplicant[language], url: '/new-applicant'}]

  return (
    <div className="container-sm">
      <TranslationButtons />
      <h2 className="form-title">{newApplicantHeadings.newApplicant[language]}</h2>
      <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      <Form
        className="applicant-form"
        formName="form"
        buttonText="Continue"
        // initialValues={InitialScreeningForm}
        submit={(form) => handleSubmit(form)}
      >
        <h5>{newApplicantHeadings.personalInfo[language]}</h5>
        <hr style={{ margin: 0 }} />

        <div className="row align-items-start mb-5">
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control required"
              classNameLabel="form-label isRequired"
              label={formLabels.firstName[language]}
              name="firstName"
              formName={'form'}
              value={ state['form']['firstName'] && state['form']['firstName']}
              innerRef={requiredInputRefs.firstName}
              errorMessage={newAppMessages.invalidFirstName[language]} />
              
          </div>
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control "
              classNameLabel="form-label"
              label={formLabels.middleNames[language]}
              name="middleNames"
              formName={'form'}
              value={ state['form']['middleNames'] && state['form']['middleNames']}
            />
          </div>
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control required"
              classNameLabel="form-label isRequired"
              label={formLabels.lastName[language]}
              innerRef={requiredInputRefs.lastName}
              name="lastName"
              formName={'form'}
              value={ state['form']['lastName'] && state['form']['lastName']}
              errorMessage={newAppMessages.invalidLastName[language]}            
              />
          </div>
          <div className="col-6 mb-3">
            <SelectInput
              className="form-select required"
              labelClass="form-label isRequired"
              label={formLabels.suffix[language]}
              name="suffix"
              innerRef={requiredInputRefs.suffix}
              options={ suffixOptions } 
              formName={'form'}
              value={ state['form']['suffix'] && state['form']['suffix']}
              errorMessage={newAppMessages.invalidSuffix[language]}
            />

          </div>
        </div>
        {/* SECTION ONE END  */}

        <div className="row align-items-start mb-5">
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control "
              classNameLabel="form-label"
              label={formLabels.address[language]}
              name="address"
              placeholder=""
              formName={'form'}
              value={ state['form']['address'] && state['form']['address']}

            />
          </div>
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control "
              classNameLabel="form-label"
              label={formLabels.city[language]}
              name="city"
              placeholder=""
              formName={'form'}
              value={ state['form']['city'] && state['form']['city']}

            />
          </div>

            <div className="col-6 mb-3">
              <SelectInput 
                className="form-select "
                label={formLabels.state[language]}
                labelClass="form-label"
                name="state" 
                options={ stateOptions }
                formName={'form'}
                value={ state['form']['state'] && state['form']['state']}
              />
            </div>
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label={formLabels.currentZip[language]}
                innerRef={requiredInputRefs.currentZip}
                name="currentZip"
                placeholder={newAppMessages.zipPlaceHolder[language]}
                errorMessage={newAppMessages.invalidZip[language]}
                formName={'form'}
                value={ state['form']['currentZip'] && state['form']['currentZip']}
              />
            </div>
            <div className="col-6 mb-3">
              <DatePickerInput
                type="text"
                className="form-control required"
                classNameLabel="form-label isRequired"
                label={formLabels.dob[language]}
                innerRef={requiredInputRefs.dob}
                name="dob"
                placeholder="YYYY-MM-DD"
                formName={'form'}
                value={ state['form']['dob'] && state['form']['dob']}
              />
            </div>
            <div className="col-6 mb-3">
            <SelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.gender[language]}
                innerRef={demoGraphicInputRefs.gender}
                name="gender" 
                options={ genderOptions } 
                formName={'form'}
                value={ state['form']['gender'] && state['form']['gender']}
  />
            </div>
        </div>
        {/* SECTION 2 END  */}
        <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              <SelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.houseHold[language]}
                innerRef={demoGraphicInputRefs.houseHold}
                name="houseHold" 
                options={ houseHoldOptions } 
                formName={'form'}
                value={ state['form']['houseHold'] && state['form']['houseHold']}
  />
            </div>
            <div className="col-6 mb-3">
              <MultiSelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.expenses[language]}
                innerRef={demoGraphicInputRefs.expenses}
                name="related_expenses"
                options={ expensesOptions } 
                formName={'form'}
                value={ state['form']['related_expenses'] && state['form']['related_expenses']}
  />
            </div>
            {/* <div className="col-6 mb-3">
              <SelectInput 
                className="form-select required"
                name="Referral Method" 
                options={ referralMethodOptions } />
            </div> */}
            </div>
          {/* SECTION DEMOGRAPHICS END */}
        <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label={formLabels.employerName[language]}
                innerRef={requiredInputRefs.employer}
                name="employerName"
                placeholder="" 
                formName={'form'}
                value={ state['form']['employerName'] && state['form']['employerName']}
                errorMessage={newAppMessages.invalidEmployer[language]}
  />
            </div>
            <div className="col-6 mb-3">
              <SelectInput 
                className="form-select required"
                labelClass="form-label isRequired"
                label={formLabels.profession[language]}
                innerRef={requiredInputRefs.profession}
                name="profession" 
                options={ professionOptions } 
                formName={'form'}
                value={ state['form']['profession'] && state['form']['profession']}
                errorMessage={newAppMessages.invalidProfession[language]}
  />
            </div>
            <div className="col-6 mb-3">
              <SelectInput 
                className="form-select required"
                labelClass="form-label isRequired"
                label={formLabels.code[language]}
                innerRef={requiredInputRefs.code}
                name="code"
                options={ socNasicCodeOptions(state.form.profession) } 
                formName={'form'}
                value={ state['form']['code'] && state['form']['code']}
                errorMessage={newAppMessages.invalidCode[language]}
  />
            </div>
            {/* <div className="col-6 mb-3">
              <SelectInput 
                className="form-select required"
                name="Referral Method" 
                options={ referralMethodOptions } />
            </div> */}
            </div>
          {/* SECTION 3 END */}
            <div className="row align-items-start mb-5">
              <h5>{newApplicantHeadings.contactInfo[language]}</h5>
              <label className="isRequired mb-1">{newAppMessages.contactIsRequired[language]}</label>
              <hr style={{margin: 0}}/>      
              <div className="col-6 mb-3">
                <div className='col-8 mb-3'>
                <SelectInput 
                  className="form-select "
                  label={formLabels.primary_country_code[language]}
                  labelClass="form-label"
                  name="primary_country_code" 
                  // value={"1 (US)"}
                  formName={'form'}
                  value={ (state['form']['primary_country_code'] && state['form']['primary_country_code']) ?? "1 (US)" }
                  options={ countryCodes } 
                  />
                </div>
                <TextInput
                  type="tel"
                  classNameInput="form-control"
                  classNameLabel="form-label"
                  label={formLabels.primaryPhone[language]}
                  name="primaryPhone"
                  innerRef={contactInputRefs.phone}
                  errorMessage={newAppMessages.invalidPhone[language]}
                  placeholder="10-digits (4408675309)" 
                  formName={'form'}
                  value={ state['form']['primaryPhone'] && state['form']['primaryPhone'] }
  
                  />
                <CheckBox
                  classNameInput="form-check-input"
                  classNameLabel="form-label"
                  label={formLabels.primary_can_text[language]}
                  name="primary_can_text"
                  formName={'form'}
                  value={ state['form']['primary_can_text'] && state['form']['primary_can_text'] }
  
                  />
              </div>
              <div className="col-6 mb-3">
                <div className='col-8 mb-3'>
                <SelectInput 
                  className="form-select "
                  label={formLabels.secondary_country_code[language]}
                  labelClass="form-label"
                  name="secondary_country_code" 
                  // value={"1 (US)"}
                  options={ countryCodes } 
                  formName={'form'}
                  value={ (state['form']['secondary_country_code'] && state['form']['secondary_country_code']) ?? "1 (US)" }
    
                  />
                </div>                
                <TextInput
                  type="tel"
                  classNameInput="form-control"
                  classNameLabel="form-label"
                  label={formLabels.secondaryPhone[language]}
                  name="secondaryPhone"
                  placeholder="10-digits (4408675309)" 
                  errorMessage={newAppMessages.invalidPhone[language]}
                  formName={'form'}
                  value={ state['form']['secondaryPhone'] && state['form']['secondaryPhone'] }
                  />
                <CheckBox
                  classNameInput="form-check-input"
                  classNameLabel="form-label"
                  label={formLabels.secondary_can_text[language]}
                  name="secondary_can_text"
                  formName={'form'}
                  value={ state['form']['secondary_can_text'] && state['form']['secondary_can_text'] }
                />
              </div>

              <div className="col-6 mb-3">
                <TextInput
                  type="email"
                  classNameInput="form-control"
                  classNameLabel="form-label"
                  label={formLabels.primaryEmail[language]}
                  name="primaryEmail"
                  innerRef={contactInputRefs.email}
                  errorMessage={newAppMessages.invalidEmail[language]}
                  placeholder="email@example.com" 
                  formName={'form'}
                  value={ (state['form']['primaryEmail'] && state['form']['primaryEmail']) || '' }
                  />
              </div>

          <div className="col-6 mb-3">
            <TextInput
              type="email"
              classNameInput="form-control"
              classNameLabel="form-label"
              label={formLabels.secondaryEmail[language]}
              errorMessage={newAppMessages.invalidEmail[language]}
              name="secondaryEmail"
              placeholder="email@example.com"
              formName={'form'}
              value={ state['form']['secondaryEmail'] && state['form']['secondaryEmail'] }
            />
          </div>
        </div>
        {/* SECTION 4 END  */}
        <div className="row align-items-start mb-5">
          <div className="col-12 mb-3">
            <CheckBox
              classNameInput="form-check-input"
              classNameLabel="form-label"
              label={formLabels.hf_subscribed[language]}
              name="hf_subscribed"
              formName={'form'}
                  value={ state['form']['hf_subscribed'] && state['form']['hf_subscribed'] }
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default NewApp;