import React, { useState, useEffect, useContext} from 'react';
import { store } from '../../globalState/StateProvider';
import useNavigatorOnline from "use-navigator-online";
import { RiWifiOffLine, RiWifiLine } from 'react-icons/ri'
import { offlineToastText } from '../../constants/formLabels';

export default function OfflineToast() {
  const { isOffline } = useNavigatorOnline()
  const { state } = useContext(store)
  const { language } = state
  const [hideCSS, setHideCSS] = useState("btn-outline-success ")

  useEffect(() => {
    if (hideCSS !== "d-none") {
      setTimeout(() => {
        setHideCSS('d-none')
      }, 3000)
    }
    else { setHideCSS("btn-outline-success") }
  }, [isOffline])

  return (
    <>
      <div className={`position-absolute flex m-4 btn w-25 ${isOffline ? "btn-outline-danger":hideCSS}`} >
        <div className="flex justify-center items center">
          {isOffline ? < RiWifiOffLine size={24} /> : < RiWifiLine size={24} /> }
        </div>
        <div className="flex flex-column justify-center items center">
          <span> {isOffline ? offlineToastText.offline[language]: offlineToastText.online[language] } </span>
        </div>
      </div>
    </>
  );
}


