import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef, useContext } from 'react'
import { store } from '../globalState/StateProvider'
import { database } from '../globalState/DexieState'
import { useParams } from "react-router-dom"
import OfflineForm from '../components/OfflineForm'
import { checkInputRefValue, validateInput } from '../services/FormValidation';
import { useNavigate } from 'react-router-dom'
import { offlineAlerts, newApplicantHeadings, breadCrumbLabels } from '../constants/formLabels';

export default function EditApplication() {
  const { state } = useContext(store);
  const language = state.language
  const [data, setData] = useState()
  const params = useParams()
  const navigate = useNavigate();

  const getDataFromDB = async (id) => {
    const application = await database.where({
      offline_id: id
    }).first()
    setData(application)
  }

  useEffect(() => {
    getDataFromDB(params.offline_id)
  }, [])

  const requiredInputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    suffix: useRef(),
    currentZip: useRef(),
    employer: useRef(),
    profession: useRef(),
    code: useRef(),
    idProofType: useRef(),
    employmentProofType: useRef(),
    offline: useRef(),
    uniqueEntrySignature: useRef(),
    selfCertSignature: useRef(),
    // removed these ref values and replaced with general value checks
    // Should look into updating refs when value is present 
    // Think this is only applicable here on edit
    // dob: useRef(),
    // idProofFile: useRef(),
    // employmentProofFile: useRef(),
  }

  //functions
  const handleChange = (key, value) => {
    setData({ ...data, [key]: value })
  }

  const handleSubmit = async () => {
    for (const [key, value] of Object.entries(requiredInputRefs)) {
      validateInput(value)
    }
    if (
      checkInputRefValue(requiredInputRefs.firstName) &&
      checkInputRefValue(requiredInputRefs.lastName) &&
      checkInputRefValue(requiredInputRefs.suffix) &&
      checkInputRefValue(requiredInputRefs.currentZip) &&
      checkInputRefValue(requiredInputRefs.employer) &&
      checkInputRefValue(requiredInputRefs.profession) &&
      checkInputRefValue(requiredInputRefs.code) &&
      checkInputRefValue(requiredInputRefs.idProofType) && 
      checkInputRefValue(requiredInputRefs.employmentProofType)
      // checkInputRefValue(requiredInputRefs.dob) &&
      && data.dob && data.employmentProofFile && data.idProofFile
    ) {
      await database.put({ ...data })
      navigate('/offline-applications-dashboard')
      alert(offlineAlerts.applicationSaved[language])
    }
    else {
      alert(offlineAlerts.fillRequiredFields[language])
    }
  }

  const breadcrumbsArray =
    [{ key: 'key-1', text: 'Dashboard', url: '/dashboard' },
    { key: 'key-2', text: 'Offline Panel', url: '/offline-applications-dashboard' },
      { key: 'key-3', text: "Edit Offline Application", url: `/:${params.offline_id}`}]
  return (
    <>
    {data&&
      <OfflineForm
        data={data}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        requiredInputRefs={requiredInputRefs} 
        pageTitle={newApplicantHeadings.editApplication[language]}
        breadcrumbsArray={breadcrumbsArray}
        />
    }
    </>
  )                  
}
