import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import NewApp from '../pages/NewApp';
import PaymentForm from '../pages/PaymentForm';
import NewAppCont from '../pages/NewAppCont';
import AppUpdate from '../pages/AppUpdate';
import ErrorPage from '../pages/Error';
import PrivateRoutes from '../services/PrivateRoutes';
import UpdateAppSearch from '../pages/UpdateAppSearch';
import NewAppResponse from '../pages/NewAppResponse';
import FinalScreen from '../pages/FinalScreen';
import AppSearchList from '../pages/AppSearchList';
import Applicant from '../pages/applicant/[id]';
import BugForm from '../pages/BugForm';
import Offline from '../pages/OfflineApp';
import StatPage from '../pages/StatPage';
import OfflineAppsDash from '../pages/OfflineAppsDash';
import ForgotPassword from '../pages/ForgotPassword';
import EditApplication from '../pages/[offline_id]';

export function AppRoutes() {

    return (
        <Router>
            <Routes>
                <Route element={<Login />} path="/login" />
                <Route element={<ForgotPassword />} path="/forgotten-password" />
                {/* Start Private ROUTES */}

                <Route element={<PrivateRoutes />}>
                    <Route element={<Dashboard />} path="/" exact />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<Dashboard />} path="/dashboard" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<OfflineAppsDash />} path="/offline-applications-dashboard" />
                </Route>

                {/* Start New App Routes */}

                <Route element={<PrivateRoutes />}>
                    <Route element={<NewApp />} path="/new-applicant" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<NewAppCont />} path="/new-applicant-cont" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<Offline />} path="/offline" />
                </Route>
                
                <Route element={<PrivateRoutes />}>
                    <Route element={<StatPage />} path="/stats" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<NewAppResponse />} path="/new-applicant-response" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<PaymentForm showHeader={true}/>} path="/payment-form" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<FinalScreen />} path="/final-screen" />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route element={<EditApplication />} path="/:offline_id" />
                </Route>


                {/* Search Routes  */}

                <Route element={<PrivateRoutes />}>
                    <Route element={<UpdateAppSearch />} path="/search" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<AppSearchList />} path="/update-application-list" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<Applicant isEdit={false} />} path="/applicant" />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route element={<Applicant isEdit={true} />} path="/edit-applicant" />
                </Route>

                {/* Below Routes are WIP  */}

                <Route element={<PrivateRoutes />}>
                    <Route element={<AppUpdate />} path="/update-application-response" />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route element={<AppUpdate />} path="/update-application-info" />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route element={<AppUpdate />} path="/update-application-confirmation" />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route element={<BugForm />} path="/bug-reporting" />
                </Route>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </Router>
    );
}