import React, { useContext, useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { store } from '../../globalState/StateProvider';
import { titleCase } from '../../services/Helpers';
// import { sanitizeFields } from '../../services/Helpers';
import Form from '../../components/Form';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import SelectInput from '../../components/SelectInput';
import MultiSelectInput from '../../components/MultiSelectInput';
import { suffixOptions } from '../../constants/suffixOptions';
import { stateOptions } from '../../constants/stateOptions'
import { professionOptions } from '../../constants/professionOptions';
import { socNasicCodeOptions } from '../../constants/socNasicCodeOptions';
import { updateAppInfo, search, fetchBeneficiary } from '../../services/UserServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../components/BreadCrumbs';
import { checkInputRefValue, validateInput } from '../../services/FormValidation';
import { countryCodes } from '../../constants/countryCodeOptions';
import RiskAssesment from '../../components/RiskAssesment/RiskAssesment';
import Modal from '../../components/Modal'
import { AiFillFileAdd } from "react-icons/ai"
import DatePickerInput from '../../components/DatePickerInput';
import { genderOptions } from '../../constants/genderOptions';
import { houseHoldOptions } from '../../constants/houseHoldOptions';
import { expensesOptions } from '../../constants/expensesOptions';
import { breadCrumbLabels, formLabels, newApplicantHeadings, newAppMessages } from '../../constants/formLabels';
import { ScreeningForm } from '../../constants/InitialScreeningForm';
import { isEqual } from 'lodash';

function Applicant({ isEdit }) {
  
  // const [isLoading, setLoading] = useState(true);
  const { state, dispatch } = useContext(store);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('edit');
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImage, setImageModal] = useState(undefined);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const aeyon_id = params.get("aeyon_id");
  const searchForm = params.get("by");
  const searchResults = params.get("searchResults");
  const language = state.language
  const handleFetchBeneficiary = async () => {
    try {
      let searchID = id;
      if (searchID === 'undefined') {
        searchID = undefined;
      };
      const refIdObject = searchID ? { id: searchID } : { aeyonId: aeyon_id };
      await fetchBeneficiary(refIdObject).then((response) => {
        dispatch({ type: 'SET_SEARCH', payload: {} });
        dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
      })
        .catch((error) => {
          handleError(error)
        });
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    handleFetchBeneficiary();
  }, [id, aeyon_id]);
  
  const requiredInputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    suffix: useRef(),
    currentZip: useRef(),
    employer: useRef(),
    profession: useRef(),
    code: useRef(),
  };

  const demoGraphicInputRefs = {
    gender: useRef(),
    houseHold: useRef(),
    expenses: useRef(),
  }

  const contactInputRefs = {
    phone: useRef(),
    email: useRef(),

  };

  const checkInputChanges = (formName) => {
    const currentBenefDetails = {
      ...ScreeningForm,
      first_name: beneficiaryDetails?.first_name,
      middle_names: beneficiaryDetails?.middle_name,
      last_name: beneficiaryDetails?.last_name,
      suffix: beneficiaryDetails?.suffix,
      family_size:beneficiaryDetails?.family_size,
      gender:beneficiaryDetails?.gender,
      related_expenses:beneficiaryDetails?.related_expenses,
      city: beneficiaryDetails?.city,
      state: beneficiaryDetails?.state,
      current_zip: beneficiaryDetails?.zip_code,
      dob: beneficiaryDetails?.date_of_birth,
      primary_phone: beneficiaryDetails?.primary_phone,
      secondary_phone: beneficiaryDetails?.secondary_phone,
      primary_country_code: beneficiaryDetails?.primary_country_code,
      primary_email: beneficiaryDetails?.primary_email,
      secondary_email: beneficiaryDetails?.secondary_email,
      employer_name: beneficiaryDetails?.employer_name,
      profession: beneficiaryDetails?.profession,
      code:beneficiaryDetails?.code,
      notes:beneficiaryDetails?.notes,
    };
    const currentFormDetails = {
      ...ScreeningForm,
      first_name: state[formName].firstName ? state[formName].firstName.trim() : null,
      middle_names: state[formName].middleNames ? state[formName].middleNames.trim() : null,
      last_name: state[formName].lastName ? state[formName].lastName.trim() : null,
      suffix: state[formName].suffix,
      family_size:state[formName].houseHold,
      gender:state[formName].gender,
      related_expenses:state[formName].related_expenses,
      city: state[formName].city ? state[formName].city.trim() : null,
      state: state[formName].state,
      current_zip: state[formName].currentZip ? state[formName].currentZip.trim() : null,
      dob: state[formName].dob,
      primary_phone: state[formName].primaryPhone ? state[formName].primaryPhone.trim() : null,
      secondary_phone: state[formName].secondaryPhone ? state[formName].secondaryPhone.trim() : null,
      primary_country_code: state[formName].primary_country_code,
      primary_email: state[formName].primaryEmail ? state[formName].primaryEmail.trim() : null,
      secondary_email: state[formName].secondaryEmail ? state[formName].secondaryEmail.trim() : null,
      employer_name: state[formName].employerName ? state[formName].employerName.trim() : null,
      profession: state[formName].profession,
      code:state[formName].code,
      notes:state[formName].notes ? state[formName].notes.trim() : '',
    };

    const isChanged = isEqual(currentBenefDetails, currentFormDetails);
    return isChanged;
  };



  //can likely be deleted once code is working. will need to update referencing code below
  const beneficiaryDetails = state.apiResponse?.beneficiaries
  const formState = state.form
  const breadcrumbsArray = [{ key: 'key-1', text: 'Dashboard', url: '/dashboard' }, { key: 'key-2', text: 'Search', url: '/search' }, { key: 'key-3', text: 'Application', url: '/applicant' }]
  const handleSearchSelect = (e) => {
    dispatch({ type: 'SET_SEARCH', payload: {} });
    navigate(`/search?by=${e.target.value}`);
  }
  const handleAllApps = () => {

    search({}).then((response) => {
      dispatch({ type: 'SET_SEARCH', payload: {} });
      dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
      navigate(`/search?by=all&showResults=true`);

    }).catch((error) => {
      handleError(error);
    });
  }

  function handleError(response) {
    if (response.error === "Invalid access token") {
      navigate('/login');
    }
    console.log(`ERROR`, response);
  }

  const BeneficiaryDetails = () => {

    if (!beneficiaryDetails || beneficiaryDetails?.length === 0) return
    return (


      <>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.first_name ? beneficiaryDetails?.first_name : '-'}</p>
          <p className="applicant-label">First Name</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.street_or_PO_box ? beneficiaryDetails?.street_or_PO_box : '-'}</p>
          <p className="applicant-label">Street/P.O. Box</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.middle_name ? beneficiaryDetails?.middle_name : '-'}</p>
          <p className="applicant-label">Middle Name(s)</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.city ? beneficiaryDetails?.city : '-'}</p>
          <p className="applicant-label">City</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.last_name ? beneficiaryDetails?.last_name : '-'}</p>
          <p className="applicant-label">Last Name(s)</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.state ? beneficiaryDetails?.state : '-'}</p>
          <p className="applicant-label">State</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.suffix ? beneficiaryDetails?.suffix : '-'}</p>
          <p className="applicant-label">Suffix</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.zip_code ? beneficiaryDetails?.zip_code : '-'}</p>
          <p className="applicant-label">Zip Code</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.profession ? beneficiaryDetails?.profession : '-'}</p>
          <p className="applicant-label">Profession</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.code ? beneficiaryDetails?.code : '-'}</p>
          <p className="applicant-label">SOC/NAICS Code</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.primary_country_code ? beneficiaryDetails?.primary_country_code : '-'}</p>
          <p className="applicant-label">Primary Country Code</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.secondary_country_code ? beneficiaryDetails?.secondary_country_code : '-'}</p>
          <p className="applicant-label">Secondary Country Code</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.primary_phone ? beneficiaryDetails?.primary_phone : '-'}</p>
          <p className="applicant-label">Primary Phone</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.secondary_phone ? beneficiaryDetails?.secondary_phone : '-'}</p>
          <p className="applicant-label">Secondary Phone</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.primary_email ? beneficiaryDetails?.primary_email : '-'}</p>
          <p className="applicant-label">Primary Email</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.secondary_email ? beneficiaryDetails?.secondary_email : '-'}</p>
          <p className="applicant-label">Secondary Email</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.intake_method ? beneficiaryDetails?.intake_method : '-'}</p>
          <p className="applicant-label">Intake Method</p>
        </div>
        <div className="col-6 mb-3">
          <p className="applicant-info">{beneficiaryDetails?.referral_method ? beneficiaryDetails?.referral_method : '-'}</p>
          <p className="applicant-label">How did beneficiaryDetails hear about our program</p>
        </div>
        <div className="col-6 mb-3">
          <strong>Status</strong> :  {beneficiaryDetails?.status}

        </div>
        <div className="col-6 mb-3">
          <strong>Notes</strong> :  {beneficiaryDetails?.notes}

        </div>
      </>
    )
  };

  const EditApplicantForm = () => {
    const isDisabled = checkInputChanges("form");

    return (
      <Form
        className=""
        formName="form"
        buttonText="Save"
        isDisabled = {isDisabled}
        submit={(form) => handleUpdateApplicant(form)}>
        <div className='row'>
          <div className="align-items-start mb-5 half">
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label="First Name"
                placeholder={beneficiaryDetails?.first_name ? beneficiaryDetails?.first_name : ''}
                value={formState.first_name ? formState.first_name : ''}
                name="firstName"
                innerRef={requiredInputRefs.firstName}
              />
            </div>
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control "
                classNameLabel="form-label"
                label="Middle Name(s)"
                placeholder={beneficiaryDetails?.middle_name === "-" ? "" : beneficiaryDetails?.middle_name}
                value={formState.middle_name === "-" ? "" : formState.middle_name}
                name="middleNames"
                innerRef={requiredInputRefs.middle_name}
              />
            </div>
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label="Last Name(s)"
                placeholder={beneficiaryDetails?.last_name}
                name="lastName"
                value={formState.last_name}
                innerRef={requiredInputRefs.lastName} />
            </div>
            <div className="mb-3">
              <SelectInput
                className="form-select required mb-3"
                labelClass="form-label isRequired"
                label="Suffix"
                name="suffix"
                value={formState.suffix}
                innerRef={requiredInputRefs.suffix}
                options={suffixOptions} />
            </div>
            <div className="mb-3">
              <SelectInput
                className="form-select required"
                labelClass="form-label isRequired"
                label="profession"
                value={formState.profession}
                innerRef={requiredInputRefs.profession}
                name="profession"
                options={professionOptions} />
            </div>
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label="Employer Name"
                placeholder={beneficiaryDetails?.employer_name}
                name="employerName"
                value={formState.employer_name}
                innerRef={requiredInputRefs.employer} />
            </div>
            <div className='col-8 mb-1'>
              <SelectInput
                className="form-select "
                label="Primary Country Code "
                labelClass="form-label"
                name="primary_country_code"
                value={formState.primary_country_code}
                options={countryCodes}
              />
            </div>
            <div className="mb-3">
              <TextInput
                type="tel"
                classNameInput="form-control"
                classNameLabel="form-label"
                label="Primary Phone Number"
                name="primaryPhone"
                placeholder={beneficiaryDetails?.primary_phone === "-" ? "" : beneficiaryDetails?.primary_phone}
                value={formState.primary_phone === "-" ? "" : formState.primary_phone}
                innerRef={contactInputRefs.phone}
                errorMessage={newAppMessages.invalidPhone[language]} />

            </div>
            <div className="mb-3">
              <TextInput
                type="email"
                classNameInput="form-control"
                classNameLabel="form-label"
                label="Primary Email"
                name="primaryEmail"
                innerRef={contactInputRefs.email}
                placeholder={beneficiaryDetails?.primary_email === "-" ? "" : beneficiaryDetails?.primary_email}
                value={formState.primary_email === "-" ? "" : formState.primary_email}
                errorMessage={newAppMessages.invalidEmail[language]} />
            </div>
            <div className="mb-3">
              <SelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.houseHold[language]}
                innerRef={demoGraphicInputRefs.houseHold}
                name="houseHold" 
                options={ houseHoldOptions } 
                formName={'form'}
                value={ formState.family_size }
              />
            </div>

          </div>

          <div className="align-items-start mb-3 half">
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control "
                classNameLabel="form-label"
                label="Street/PO Box Address"
                placeholder={beneficiaryDetails?.street_or_PO_box === '-' ? "" : beneficiaryDetails?.street_or_PO_box}
                name="address"
                value={formState.street_or_PO_box === '-' ? "" : formState.street_or_PO_box}
              />
            </div>
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control"
                classNameLabel="form-label"
                label="City"
                placeholder={beneficiaryDetails?.city === "-" ? "" : beneficiaryDetails?.city}
                name="city"
                value={formState.city === "-" ? "" : formState.city}
              />
            </div>
            <div className="mb-3">
              <SelectInput
                className="form-select "
                label="State"
                labelClass="form-label"
                value={formState.state === "-" ? "" : formState.state}
                name="state"
                options={stateOptions} />
            </div>
            <div className="mb-3">
              <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label="CURRENT RESIDENCY ZIPCODE"
                placeholder={beneficiaryDetails?.zip_code === "-" ? "" : beneficiaryDetails?.zip_code}
                name="currentZip"
                value={formState.zip_code}
                innerRef={requiredInputRefs.currentZip}
                errorMessage={newAppMessages.invalidZip[language]} />
            </div>
            <div className="mb-3">
            <DatePickerInput
                type="text"
                className="form-control required"
                classNameLabel="form-label isRequired"
                label="Date of Birth"
                innerRef={requiredInputRefs.dob}
                name="dob"
                placeholder={beneficiaryDetails?.date_of_birth}
                formName={'form'}
                value={ formState.dob == ''? formState.date_of_birth: formState.dob }
  
                />
              {/* <TextInput
                type="text"
                classNameInput="form-control required"
                classNameLabel="form-label isRequired"
                label="Date of Birth"
                name="dob"
                innerRef={requiredInputRefs.dob} /> */}
            </div>
            <div className="mb-3">
              <SelectInput
                className="form-select required"
                labelClass="form-label isRequired"
                label="SOC/NAICS Code"
                value={formState.code}
                innerRef={requiredInputRefs.code}
                name="code"
                options={socNasicCodeOptions(state.form.profession)} />
            </div>
            <div className='col-8 mb-1'>
              <SelectInput
                className="form-select "
                label="Secondary Country Code "
                labelClass="form-label"
                name="secondary_country_code"
                value={formState.secondary_country_code}
                options={countryCodes}
              />
            </div>
            <div className="mb-3">
              <TextInput
                type="tel"
                classNameInput="form-control"
                classNameLabel="form-label"
                label="Secondary Phone Number"
                name="secondaryPhone"
                placeholder={beneficiaryDetails?.secondary_phone === "-" ? "" : beneficiaryDetails?.secondary_phone}
                value={formState.secondary_phone === "-" ? "" : formState.secondary_phone}
                errorMessage={newAppMessages.invalidPhone[language]} />
            </div>
            <div className="mb-3">
              <TextInput
                type="email"
                classNameInput="form-control"
                classNameLabel="form-label"
                label="Secondary Email"
                name="secondaryEmail"
                placeholder={beneficiaryDetails?.secondary_email === "-" ? "" : beneficiaryDetails?.secondary_email}
                value={formState.secondary_email === "-" ? "" : formState.secondary_email}
                errorMessage={newAppMessages.invalidEmail[language]} />
            </div>
            <div className="mb-3">
              <MultiSelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.expenses[language]}
                innerRef={demoGraphicInputRefs.expenses}
                name="related_expenses"
                options={ expensesOptions } 
                formName={'form'}
                value={ formState.related_expenses}
                multiple={true}
            />
            </div> 
            <div className="mb-3">
            <SelectInput 
                className="form-select"
                labelClass="form-label"
                label={formLabels.gender[language]}
                innerRef={demoGraphicInputRefs.gender}
                name="gender" 
                options={ genderOptions } 
                formName={'form'}
                value={ formState.gender }
            />
            </div> 
          </div>
          <div className="mb-3">
            <Textarea
                className="form-control"
                label="Notes"
                name="notes"
                placeholder={beneficiaryDetails?.notes}
                formName={'form'}
                value={ formState.notes }
              />
            </div>
        </div>

      </Form>
    )
  };
  const ImageModal = () => {
    return (
      <div className={`${showImageModal ? '' : 'hide'}`}>
        <div className="card support-docs m-3 full-width">
          <span className='close' onClick={() => setShowImageModal(false)}>Close</span>
          <img className="m-3" src={modalImage?.url} alt={modalImage?.doc_type} />
          <div className="card-body">
            <h5 className="card-title">{modalImage?.doc_type}</h5>
          </div>
          <div className="card-footer">
            <small className="text-body-secondary">
              Uploaded on {modalImage?.created_at}
            </small>
          </div>
        </div>
      </div>

    )
  }

  const handleEditApplicant = (e) => {
    const path = e.target.value === "edit" ? "/edit-applicant" : "/applicant";

    setActiveButton(e.target.value === "edit" ? "cancel" : "edit");
        dispatch({ type: 'SET_FORM', payload: {...beneficiaryDetails, dob: beneficiaryDetails?.date_of_birth} })
    navigate(`${path}?id=${beneficiaryDetails?.id}${searchResults ? "&searchResults=true" : ""}`);
  };

  const handleClick = (e) => {
    if (id) {
      navigate(`/search?by=refId&id=${id}&showResults=true`);
    };
  }
  // handles applicant data submission
  const handleUpdateApplicant = async (form) => {
    for (const [key, value] of Object.entries(requiredInputRefs)) {
      validateInput(value, key);
    };

    if (!contactInputRefs.email.current.value && !contactInputRefs.phone.current.value) {
      alert('Please enter a phone number or email.');
      return false;
    };

    const name = checkInputRefValue(requiredInputRefs.firstName) && checkInputRefValue(requiredInputRefs.lastName) && checkInputRefValue(requiredInputRefs.suffix);
    const contact = checkInputRefValue(contactInputRefs.phone) || checkInputRefValue(contactInputRefs.email);

    if (name && checkInputRefValue(requiredInputRefs.currentZip) && state.form.dob && checkInputRefValue(requiredInputRefs.employer) && checkInputRefValue(requiredInputRefs.profession) && checkInputRefValue(requiredInputRefs.code) && contact) {
      const finalRequest = { ...state.form, ...form };

      dispatch({ type: 'SET_FORM', payload: finalRequest});
      dispatch({ type: 'SET_RESPONSE', payload: { ...state.apiResponse, isLoading: true } });

      updateAppInfo(parseInt(id), finalRequest).then((response) => {
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false, beneficiaries: response.data  } });
        setActiveButton( "edit");
        navigate(`/applicant?id=${parseInt(id)}`);
      })
        .catch((error) => {
          console.log(`ERROR: ${error}`);
              dispatch({ type: 'SET_RESPONSE', payload: {...state.apiResponse, isLoading: false } });
              alert("Uh-oh, the following error occurred: \n" + error.error);
        });
    };
  };
  const handleImageModal=(imageObject)=>{
    setShowImageModal(true);
    setImageModal(imageObject);
  }
  const handlePayment = () => {
    navigate(`/payment-form?id=${beneficiaryDetails?.id}`);
  };

  return (
    <>
      <div className="container-sm">
        <h2 className="form-title">Update Applicant</h2>
        <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        <div className="row align-items-start">
          <div onClick={() => handleSearchSelect({ target: { value: 'refId' } })} className="col-4 mb-3 all-applications">
            <div className="d-inline " id="svg-icon-container">
              <svg id="input-search-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6.5" cy="6.5" r="5.75" stroke="#C5C7CD" strokeWidth="1.5" />
                <path d="M11 11L15 15" stroke="#C5C7CD" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </div>
            <span className={`search-applicants search-nav  ${searchForm === "all" ? null : 'selected'}`}>
              Search
            </span>
          </div>
          <div onClick={() => handleAllApps()} className={`col-4 mb-3 all-applications ${searchForm === "all" ? 'selected' : null} padding-offset`}>
            <div className="d-inline " id="svg-icon-container">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M2.4 5.20002C3.2825 5.20002 4 4.48252 4 3.60002C4 2.71752 3.2825 2.00002 2.4 2.00002C1.5175 2.00002 0.8 2.71752 0.8 3.60002C0.8 4.48252 1.5175 5.20002 2.4 5.20002ZM13.6 5.20002C14.4825 5.20002 15.2 4.48252 15.2 3.60002C15.2 2.71752 14.4825 2.00002 13.6 2.00002C12.7175 2.00002 12 2.71752 12 3.60002C12 4.48252 12.7175 5.20002 13.6 5.20002ZM14.4 6.00002H12.8C12.36 6.00002 11.9625 6.17753 11.6725 6.46502C12.68 7.01753 13.395 8.01503 13.55 9.20003H15.2C15.6425 9.20003 16 8.84253 16 8.40003V7.60003C16 6.71753 15.2825 6.00002 14.4 6.00002ZM8 6.00002C9.5475 6.00002 10.8 4.74752 10.8 3.20002C10.8 1.65252 9.5475 0.400024 8 0.400024C6.4525 0.400024 5.2 1.65252 5.2 3.20002C5.2 4.74752 6.4525 6.00002 8 6.00002ZM9.92 6.80002H9.7125C9.1925 7.05002 8.615 7.20003 8 7.20003C7.385 7.20003 6.81 7.05002 6.2875 6.80002H6.08C4.49 6.80002 3.2 8.09003 3.2 9.68003V10.4C3.2 11.0625 3.7375 11.6 4.4 11.6H11.6C12.2625 11.6 12.8 11.0625 12.8 10.4V9.68003C12.8 8.09003 11.51 6.80002 9.92 6.80002ZM4.3275 6.46502C4.0375 6.17753 3.64 6.00002 3.2 6.00002H1.6C0.7175 6.00002 0 6.71753 0 7.60003V8.40003C0 8.84253 0.3575 9.20003 0.8 9.20003H2.4475C2.605 8.01503 3.32 7.01753 4.3275 6.46502Z" fill="#9FA2B4" />
              </svg>
            </div>
            <div className='d-inline ms-2 '>
              <span className='offgrey'>All Applications</span>
            </div>

          </div>
        </div>

        <div className="row align-items-start existing-applicant-form">
          <div className="col-12">
            {beneficiaryDetails && beneficiaryDetails?.length !== 0 && <div className="">
              <button className={`btn ${activeButton === 'edit' ? 'btn-primary' : 'btn-danger'} btn-sm mb-3 mr-2 float-end`} value={`${activeButton}`} onClick={handleEditApplicant} disabled={beneficiaryDetails?.status==="paid"}>{titleCase(activeButton)}</button>
              {!isEdit &&
                <>
                  <button type="button" className="btn btn-secondary btn-sm float-end mx-2" data-bs-toggle="modal" data-bs-target="#addDocModal">
                    <AiFillFileAdd /> Add Documents
                  </button>
                  <button type="button" className="btn btn-primary btn-sm float-end mx-2" onClick={handlePayment}>
                    Edit Payment
                  </button>
                </>
              }
            </div>
            }

            {/* {searchResults && <button className="btn btn-secondary btn-sm me-1 float-end" onClick={handleClick}>Return to Results</button>} */}
          </div>
          {!isEdit ? BeneficiaryDetails() : EditApplicantForm()}
          <div className="col-12 mb-3">
            <br />
            <div className="dropdown">
              <div className="accordion-body">
                <RiskAssesment response={beneficiaryDetails} />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <h3 >
              <AiFillFileAdd /> Supporting Documents
            </h3>
            <div className='flex flex-wrap'>
              {beneficiaryDetails?.supporting_docs &&
                beneficiaryDetails?.supporting_docs
                  // .filter((docs) => docs.doc_type === "Gift Card Proxy ID")
                  .map((docs) => (
                    <div
                      key={docs.id}
                      className="card support-docs m-3"
                      onClick={() => handleImageModal(docs)}
                    >
                      <img className="m-3" src={docs.url} alt={docs.doc_type} />
                      <div className="card-body">
                        <h5 className="card-title">{docs.doc_type}</h5>
                      </div>
                      <div className="card-footer">
                        <small className="text-body-secondary">
                          Uploaded on {docs.created_at}
                        </small>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <ImageModal />
          {/* {searchResults && <button className="btn btn-secondary mt-5 flex center" onClick={handleClick}>Return to Results</button>} */}
        </div>
      </div>
      <Modal compId={"addDocModal"} reportId={id}/>
    </>
  );
}

export default Applicant;
