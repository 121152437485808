export const InitialScreeningForm = {
    firstName: '',
    middleNames: '',
    lastName: '',
    suffix:'',
    familySize:'',
    gender:'',
    relatedExpenses:'',
    address: '',
    city: '',
    state: '', 
    currentZip: '',
    dob: '',
    primaryPhone: '',
    secondaryPhone: '',
    primaryEmail: '',
    secondaryEmail: '',
    employerName: '',
    profession:'',
    code:'',
    intake_method: '',
    referral_method: '',
    status:'',
    employmentProofType:'',
    employmentProofFile: '',
    idProofFile: '',
    idProofType:'',
    signatureFile: '',
    cc: '',
    bankName: '',
    accountNumber: '',
    routingNumber: '',
    notes:'',
};

export const ScreeningForm = {
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix:'',
    family_size:'',
    gender:'',
    related_expenses:'',
    address: '',
    city: '',
    state: '', 
    current_zip: '',
    dob: '',
    primary_phone: '',
    secondary_phone: '',
    primary_country_code: '',
    primary_email: '',
    secondary_email: '',
    employer_name: '',
    profession:'',
    code:'',
    intake_method: '',
    referral_method: '',
    status:'',
    employment_proof_type:'',
    employment_proof_file: '',
    id_proof_file: '',
    id_proof_type:'',
    signature_file: '',
    cc: '',
    bank_name: '',
    account_number: '',
    routing_number: '',
    notes:'',
};