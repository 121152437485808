import { useContext, useState, useRef } from 'react';
import Form from '../components/Form';
import TextInput from '../components/TextInput';
import FileUpload from '../components/FileUpload';
import { store } from '../globalState/StateProvider';
import { addPaymentInfo, postSupportingDocs } from '../services/UserServices';
import { convertImagesToBase64 } from '../services/Helpers';
import { checkInputRefValue } from '../services/FormValidation';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../components/BreadCrumbs';

function NewApp(props) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);

  const urlParams = new URLSearchParams(window.location.search);
  const applicantId = urlParams.get('id');
  const userDetails = state.apiResponse?.beneficiaries

  const [isGiftCardForm, setIsGiftCard] = useState(true);

  const paymentRefs = {
    cc: useRef(),
    bankName: useRef(),
    accountNumber: useRef(),
    routingNumber: useRef(),
    proxyProofFile: useRef(),
  }
  const submitGiftCard = async (form) => {
    const imageArray = [paymentRefs.proxyProofFile.current.files[0]];
  
    if (form.cc && checkInputRefValue(paymentRefs.proxyProofFile)) {
      //Sets loader to true
      dispatch({ type: 'SET_RESPONSE', payload: { ...state.apiResponse, isLoading: true } });
  
      try {
        const base64Image = await convertImagesToBase64(imageArray);
  
        const supportingDocParams = {
          doc_type: 'Gift Card Proxy ID',
          image: base64Image[0],
        };
  
        const finalRequest = { ...state.form, ...form };
  
        dispatch({ type: 'SET_FORM', payload: finalRequest });
  
        await addPaymentInfo(applicantId, form);
  
        // Await the completion of postSupportingDocs before proceeding
        await postSupportingDocs(supportingDocParams, applicantId);
  
        dispatch({ type: 'SET_RESPONSE', payload: { ...state.apiResponse, isLoading: false } });
  
        dispatch({ type: 'CLEAR_FORM', payload: {} });
        navigate(`/final-screen?id=${applicantId}`);
      } catch (error) {
        dispatch({ type: 'SET_RESPONSE', payload: { ...state.apiResponse, isLoading: false } });
        alert("Uh-oh, the following error occurred: \n" + error);
      }
    } else {
      alert('Please make sure you input a proxy number, and attach a picture');
    }
  };

  const submitACH = (form) => {
    if (form.accountNumber && form.routingNumber) {
      addPaymentInfo(applicantId, form).then((response) => {
        navigate(`/final-screen?id=${applicantId}`);
      })
        .catch((error) => {
          //To do need to handle error show a toast 
          console.log(`ERROR: ${error}`);
        });
    } else {
      alert('Please fill out appropriate fields.');
    };
  }
  const handleSubmit = (form) => {

    return isGiftCardForm ? submitGiftCard(form) : submitACH(form)

  };


  const breadcrumbsArray = [{ key: 'key-1', text: 'Dashboard', url: '/dashboard' }, { key: 'key-2', text: 'New Applicant', url: '/new-applicant?clear=true' }, { key: 'key-3', text: 'Proofs & Attestations', url: '/new-applicant-cont?clear=true' }, { key: 'key-4', text: 'Risk Assesment', url: '/new-applicant-response' }, { key: 'key-5', text: 'Payment Info', url: '/payment-form' }]
  return (
    <div className="container-sm">
      {props.showHeader && (<>
        <h2 className="form-title">New Applicant</h2>
        <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      </>)}
      <Form
        className="applicant-form"
        formName="form"
        buttonText="Continue"
        submit={(form) => handleSubmit(form)}>
        <h5>Payment Info</h5>
        <hr style={{ margin: 0 }} />

        <div className="row align-items-start mb-5">
          <div className="col-6 mb-3">
            <div className="select">
              <label htmlFor="Payment Form" className="form-label">
                Payment Form
              </label>
              <select
                name="Payment Form"
                className="form-select"
                id="payment-form"
                onChange={() => setIsGiftCard(!isGiftCardForm)}
              >
                <option value="Gift Card">Gift Card</option>
                <option value="Bank Account">Bank Account</option>
              </select>
            </div>
          </div>
          {isGiftCardForm ? (
            <div className="row align-items-start mb-5">
              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  classNameInput="form-control required"
                  classNameLabel="form-label isRequired"
                  label="Proxy Number (PID)"
                  name="cc"
                  innerRef={paymentRefs.cc}
                  value={userDetails?.card_proxy}
                />
              </div>
              <div className="col-6 mb-3">
                <FileUpload
                  className="file-upload form-control required"
                  label="Proof of Proxy"
                  name="proxyProofFile"
                  innerRef={paymentRefs.proxyProofFile}
                  placeholder=""
                />
              </div>
              {userDetails?.supporting_docs &&
                userDetails.supporting_docs
                  .filter((docs) => docs.doc_type === "Gift Card Proxy ID")
                  .map((docs) => (
                    <div
                      key={docs.id}
                      className="card support-docs m-3"
                    >
                      <img className="mt-3" src={docs.url} alt={docs.doc_type} />
                      <div className="card-body">
                        <h5 className="card-title">{docs.doc_type}</h5>
                      </div>
                      <div className="card-footer">
                        <small className="text-body-secondary">
                          Uploaded on {docs.created_at}
                        </small>
                      </div>
                    </div>
                  ))}
            </div>
          ) : (
            <div className="row align-items-start mb-5">
              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  classNameInput="form-control required"
                  classNameLabel="form-label isRequired"
                  label="Bank Name"
                  name="bankName"
                  innerRef={paymentRefs.bankName}
                />
              </div>
              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  classNameInput="form-control required"
                  classNameLabel="form-label isRequired"
                  label="Account Number"
                  name="accountNumber"
                  innerRef={paymentRefs.accountNumber}
                />
              </div>
              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  classNameInput="form-control required"
                  classNameLabel="form-label isRequired"
                  label="Routing Number"
                  name="routingNumber"
                  innerRef={paymentRefs.routingNumber}
                />
              </div>
            </div>
          )}
        </div>
        {/* SECTION ONE END  */}
      </Form>
    </div>
  );
}

export default NewApp;