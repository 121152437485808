import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners';
import { database } from '../../globalState/DexieState';
import { offlinePost } from '../../services/offlinepost';
// import moment from 'moment';
// import { getUTCDate } from '../../services/Helpers';


export default function Application({ idx, app, isOnline, isConfirmation, error }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const getAppFromIndexDB = (id) => {
    navigate(`/${id}`)
  }
  const renderButtons = () => {
    return loading ?
      <div className="d-flex justify-content-center algin-items-center ml-2">
        <ClipLoader cssOverride={{
          color: "#DDE2FF",
          size: "24px"
        }} />
      </div>
      : <>
        <button
          className="btn btn-primary"
          disabled={!isOnline}
          onClick={() => postSelectedOfflineForm(app)}> Upload</button>
        <button
          className="btn btn-secondary mx-1"
          onClick={() => getAppFromIndexDB(app.offline_id)}>
          Edit
        </button>
      </>
  }
  const renderConfirmationFeedback = () => {
    return error ? <> <span className="fs-5 ms-2">Error</span> <br /> {error.map((err, i) => <span key={i+app.id+"error-message"} className="fs-10 ms-2" > {err}</span>)}</>
      : <>
        <span className="fs-5 ms-2">Reference ID: {app.id} </span>
        <br />
        <span className="fs-5 ms-2">Validation ID: {app.validation_response?.beneficiary?.id} </span>
      </>
  }
  const postSelectedOfflineForm = async (app) => {
    setLoading(true)
    await offlinePost(app)
      .then(resp => {
        if (resp.status === 201) {
          console.log("status: ", resp.data.offline_id)
          database.delete(app.id).then(() => {
            console.log('Entry deleted successfully');
          })
          .then(() => window.location.reload())
          .catch((err) => {
            console.error('Error deleting entry', err);
          });
        }
      })
      .catch(error =>{
        alert(`error occurd: ${error.response.data[0]}`)
        setLoading(false)
      })
  }
// TO-DO:  Throwing an object error
  // const dateFixed = app.date_of_birth && getUTCDate(app.date_of_birth);
  // const dateString = moment(dateFixed).format("yyyy-M-d") 
  
  const borderClass = () => !error ? isConfirmation ? 'border-success' : '' : 'border-warning';

  return (
    <>
      <div key={idx} className={`border ${borderClass()} rounded p-1 my-1  d-flex justify-content-between align-items-center`}>
        <div>
          <span className="fs-5 ms-2 text-muted">Application</span> <br />
          <span className="ms-2"> <span className="text-muted">Full Name:</span> {app.firstName ?? app.first_name }{app.middleName?? app.middle_name} {app.lastName ?? app.last_name } {app.suffix !== "None" ? app.suffix : ""}</span><br />
          {/* <span className="ms-2"> <span className="text-muted"> Date of Birth:</span> {app.dob ?? dateString} </span><br /> */}
          <span className="ms-2"> <span className="text-muted"> Current Zip Code:</span> {app.currentZip ?? app.zip_code} </span><br />
          <span className="ms-2">
            <span className="text-muted"> Occupation:</span> {app.profession} at {app.employerName ?? app.employer_name }
          </span>
        </div>
        <div>
          {!isConfirmation? renderButtons() : renderConfirmationFeedback()}
        </div>
      </div>
    </>
  )
}
