import { useContext, useEffect, useRef, useState } from 'react';
import Form from '../components/Form';
import FileUpload from '../components/FileUpload';
import SelectInput from '../components/SelectInput';
import { employmentProofOptions } from '../constants/employmentProofOptions';
import { identityProofOptions } from '../constants/identityProofOptions';
import { store } from '../globalState/StateProvider';
import { postAppInfo } from '../services/UserServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { InitialScreeningForm } from '../constants/InitialScreeningForm';
import Breadcrumbs from '../components/BreadCrumbs';
import CheckBox from '../components/CheckBox';
import Textarea from '../components/Textarea';
import { checkInputRefValue, validateInput } from '../services/FormValidation';
import { covidAttestation, uniqueEntryAttestation, finalCert } from '../constants/attestations';
import SignaturePad from 'react-signature-canvas'
import { breadCrumbLabels, formLabels, newApplicantHeadings } from '../constants/formLabels';
import TranslationButtons from '../components/TranslationButtons';


function NewAppCont() {

  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);
  const language = state.language;
  const proofRefs = {
    idProofFile: useRef(),
    idProofFileType: useRef(),
    employmentProofFile: useRef(),
    employmentProofType: useRef(),

  }
  const signatureRef = useRef();
  const sigPad = signatureRef.current;

  const [trimmedDataURL, setTrimmedURL] = useState('');

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      field: "signatureFile",
      value: trimmedDataURL,
      form: 'form'
    });

  }, [dispatch, sigPad, trimmedDataURL])

  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const clearFormState = searchParams.get("clear");

  // If hitting this page from breadcrubs array was no easy way to clear our state, so used url params 
  useEffect(() => {
    clearFormState && dispatch({ type: 'CLEAR_FORM', payload: {} });
  }, [paramsString])
  
  const clear = (e) => {
    e.preventDefault()
    sigPad.clear()
    setTrimmedURL('')
  }
  const trim = async (e) => {
    e.preventDefault()
    let url = sigPad && !sigPad.isEmpty() && await sigPad.getTrimmedCanvas().toDataURL('image/png')
    setTrimmedURL(url)
  }
  const renderErrors = (errors) =>{
    let text=''
    errors.map((e)=>{
      console.log(e)
      text = text + e + `\n`
    })
    return text
  }
  const handleSubmit = (form) => {
    if (state.form.covid_attestation && state.form.unique_entry_attestation && trimmedDataURL) {
      // converts image files to base64 format
      for (const [key, value] of Object.entries(proofRefs)) {
        validateInput(value, key)
      }

      if (checkInputRefValue(proofRefs.idProofFile) && checkInputRefValue(proofRefs.employmentProofFile) && checkInputRefValue(proofRefs.idProofFileType) && checkInputRefValue(proofRefs.employmentProofType)) {

        const finalRequest = { ...state.form, ...form };
    
        dispatch({ type: 'SET_FORM', payload: finalRequest });
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: true } });
        if (checkInputRefValue(proofRefs.idProofFile) && checkInputRefValue(proofRefs.employmentProofFile)) {
          postAppInfo(finalRequest).then((response) => {
            dispatch({ type: 'SET_RESPONSE', payload: { ...response, isLoading: false } });
            navigate(`/new-applicant-response?id=${response.data.id}`);
          })
            .catch((error) => {
              //Some errors are coming back as array some as error object. This handles both
              let displayError = Array.isArray(error) ? renderErrors(error) : error.error
              dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
              alert("Uh-oh, the following error occurred: \n" + displayError)
            });
        };
      } else {
        alert("Uh-oh, please review the proof of identification and proof of employment ")
      };
    } else {

      alert("Uh-Oh seems like you're missing an attestation, or signature! Please review and re-submit")
    }
  };
  const SiggyComponents = () => {
    return (
      <div className={"sig-container"}>
        <div className={"sig"} style={{ display: trimmedDataURL ? 'none' : 'inline-block' }} >
          <SignaturePad canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            ref={signatureRef} />
        </div>

        {trimmedDataURL
          ? <div className='flex sig sig-container sig-saved'><img alt="saved-siggy" className={"sig-image sig-saved"}
            src={trimmedDataURL} />
            <span className='flex sig-saved '>
              {"Signature Saved"}
            </span>
          </div>
          : null}
        <br />
        <div>
          <button className={"siggy-buttons"} onClick={(e) => clear(e)}>
            Clear
          </button>
          <button className={"siggy-buttons"} onClick={(e) => trim(e)}>
            Save
          </button>

        </div>
        <p>{finalCert[language]}</p>
      </div>
    )
  }
  const breadcrumbsArray = [{ key: 'key-1', text: breadCrumbLabels.dashboard[language], url: '/dashboard' }, { key: 'key-2', text: breadCrumbLabels.newApplicant[language], url: '/new-applicant' }, { key: 'key-3', text: breadCrumbLabels.proofsAndAttestations[language], url: '/new-applicant-cont' }]
  return (
    <div className="container-sm">
      <TranslationButtons />
      <h2 className="form-title">{newApplicantHeadings.newApplicant[language]}</h2>

      <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      <Form
        className="applicant-form"
        initialValues={InitialScreeningForm}
        formName="form"
        buttonText="Continue"
        submit={(form) => handleSubmit(form)}>
        <h5>{newApplicantHeadings.proofs[language]}</h5>
        <hr style={{ margin: 0 }} />
        <div className="row align-items-start">
          <div className="col-5 mb-3">
            <SelectInput
              className="form-select required"
              labelClass="form-label isRequired"
              label={formLabels.idProof[language]}
              name="idProofType"
              innerRef={proofRefs.idProofFileType}
              options={identityProofOptions} 
              formName={'form'}
              value={ state['form']['idProofType'] && state['form']['idProofType']}
              />
          </div>
          <div className="col-7 mb-3">
            <FileUpload
              className="file-upload form-control required"
              label={formLabels.idProof[language]}
              name="idProofFile"
              innerRef={proofRefs.idProofFile}
              placeholder="" />
          </div>
        </div>

        <div className="row align-items-start mb-5">
          <div className="col-5 mb-3">
            <SelectInput
              className="form-select required"
              labelClass="form-label isRequired"
              label={formLabels.employmentProof[language]}
              name="employmentProofType"
              innerRef={proofRefs.employmentProofType}
              options={employmentProofOptions} 
              formName={'form'}
              value={ state['form']['employmentProofType'] && state['form']['employmentProofType']}
              />
          </div>
          <div className="col-7 mb-3">
            <FileUpload
              className="file-upload form-control required"
              label={formLabels.employmentProof[language]}
              name="employmentProofFile"
              innerRef={proofRefs.employmentProofFile}
              placeholder="" />
          </div>
        </div>

        <div className="row align-items-start mb-5">
          <div className="col-12 mb-3">
            <Textarea
              className="form-control"
              label={formLabels.notes[language]}
              name="notes"
              placeholder=""
              formName={'form'}
              value={ state['form']['notes'] && state['form']['notes']}
            />
          </div>
        </div>
        <h5>{newApplicantHeadings.attestations[language]}</h5>
        <hr style={{ margin: 0 }} className="mb-3" />
        <div className="row align-items-start mb-5">
          <div className="col-12 mb-3">
            <CheckBox
              classNameInput="form-check-input"
              classNameLabel="form-label"
              label={covidAttestation[language]}
              name="covid_attestation"
              formName={'form'}
              value={ state['form']['covid_attestation'] && state['form']['covid_attestation']}
            />
          </div>
        </div>

        <div className="row align-items-start mb-5">
          <div className="col-12 mb-3">
            <CheckBox
              classNameInput="form-check-input"
              classNameLabel="form-label"
              label={uniqueEntryAttestation[language]}
              name="unique_entry_attestation"
              formName={'form'}
              value={ state['form']['unique_entry_attestation'] && state['form']['unique_entry_attestation']}
            />
          </div>
        </div>
        {SiggyComponents()}
      </Form>


    </div>
  );
}

export default NewAppCont;