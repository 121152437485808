export const identityProofOptions = [
  { label: "International identification document", value: "International identification document" },
  { label: "H2A/H2B visa", value: "H2A/H2B visa" },
  { label: "Matrícula consular", value: "Matrícula consular" },
  { label: "Non-Photo identification", value: "Non-Photo identification" },
  { label: "School identification card", value: "School identification card" },
  { label: "Any state-issued ID card", value: "Any state-issued ID card" },
  { label: "Any state-issued driver's license", value: "Any state-issued driver's license" },
  { label: "Passport (any country)", value: "Passport (any country)" },
  { label: "Work identification card", value: "Work identification card" },
];