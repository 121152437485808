import { useContext } from 'react';
import { FormContext } from '../Form';
import { validateInput } from '../../services/FormValidation';
import { convertImagesToBase64 } from '../../services/Helpers';

function FileUpload(props) {
  const {
    label,
    name,
    className,
    innerRef,
    placeholder,
    formName = 'form',
  } = props;

  const { state, dispatch } = useContext(FormContext);
  const handleChange = e => {
    validateInput(e);
    convertImagesToBase64([e.target.files[0]]).then(result => {
      let base64Image = result[0];
      validateInput(e);
      dispatch({
        type: 'UPDATE_FIELD',
        field: e.target.name,
        value: base64Image,
        form: formName
      });
    }).catch(error=> {
    //Clearing ref and state && changing class prop to invalid
      innerRef.current.value = "";
      innerRef.current.className = "file-upload form-control required is-invalid";

      dispatch({
      type: 'UPDATE_FIELD',
      field: e.target.name,
      value: "",
      form: formName
    })
  })
  };
  let formValue = state[formName] && state[formName][name];

  return (
    <div className="upload-btn">
      <input
        type="file"
        className={className}
        name={name}
        id={name}
        ref={innerRef}
        placeholder={placeholder}
        // value={state.fieldName}
        onChange={handleChange}
      />
    {/* Displays Image to be uploaded */}
      {formValue ? <>
        <label htmlFor={name} className={'form-label'}>{"Selected File:"}</label>
        <img src={formValue} alt={name} className="img-thumbnail" />
      </>
        : ""}
    </div>
  )
}

export default FileUpload;