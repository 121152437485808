import React from 'react'
import { Link } from 'react-router-dom';

const BugForm = () => {
    return (
        <div className="wrapper white-bg">
            <Link className='bug-button' to='/Dashboard'> Back</Link>
            <iframe title="Bug-report" className="airtable-embed" src="https://airtable.com/embed/shrkEIs40EgYdm2xg?backgroundColor=cyan" frameBorder="0"  width="100%" height="533" style={{ height: '110vh' }}></iframe>
        </div>
    )
}

export default BugForm