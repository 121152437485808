import { useState,  useRef, useContext } from 'react';
import { store } from '../globalState/StateProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { database } from '../globalState/DexieState';
import { checkInputRefValue, validateInput } from '../services/FormValidation';
import { v4 as uuid } from 'uuid';
import { offlineAlerts, newApplicantHeadings, breadCrumbLabels } from '../constants/formLabels';
import OfflineForm from '../components/OfflineForm';



export default function Offline() {
  const { state } = useContext(store);
  const language = state.language
  const id = uuid()
  const navigate = useNavigate();
  const [data, setData] = useState({
    firstName: '',
    middleNames: '',
    lastName: '',
    suffix: '',
    address: '',
    city: '',
    state: '',
    currentZip: '',
    // dob: '',
    primary_country_code: '1 (US)',
    primaryPhone: '',
    secondary_country_code: '1 (US)',
    secondaryPhone: '',
    primaryEmail: '',
    secondaryEmail: '',
    employerName: '',
    profession: '',
    code: '',
    intake_method: '',
    referral_method: '',
    status: '',
    employmentProofType: '',
    employmentProofFile: '',
    idProofFile: '',
    idProofType: '',
    selfCertSignature: '',
    uniqueEntrySignature: '',
    offline: "",
    signature: "",
    hf_subscribed: false,
    offline_id: id
  })

  const requiredInputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    suffix: useRef(),
    currentZip: useRef(),
    // dob: useRef(),
    employer: useRef(),
    profession: useRef(),
    code: useRef(),
    idProofFile: useRef(),
    idProofType: useRef(),
    employmentProofFile: useRef(),
    employmentProofType: useRef(),
    offline: useRef(),
    uniqueEntrySignature: useRef(),
    selfCertSignature: useRef(),
  }

  //functions

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value })
  }

  const handleSubmit = async () => {
    for (const [key, value] of Object.entries(requiredInputRefs)) {
      validateInput(value)
    }
    if (
      checkInputRefValue(requiredInputRefs.firstName) &&
      checkInputRefValue(requiredInputRefs.lastName) &&
      checkInputRefValue(requiredInputRefs.suffix) &&
      checkInputRefValue(requiredInputRefs.currentZip) &&
      // checkInputRefValue(requiredInputRefs.dob) &&
      checkInputRefValue(requiredInputRefs.employer) &&
      checkInputRefValue(requiredInputRefs.profession) &&
      checkInputRefValue(requiredInputRefs.code) &&
      checkInputRefValue(requiredInputRefs.idProofFile) &&
      checkInputRefValue(requiredInputRefs.idProofType) &&
      checkInputRefValue(requiredInputRefs.employmentProofFile) &&
      checkInputRefValue(requiredInputRefs.employmentProofType) && data.dob
    ) {
      await database.add({ ...data })
      navigate('/')
      alert(offlineAlerts.applicationSaved[language])
    }
    else {
      alert(offlineAlerts.fillRequiredFields[language])
    }
  }
  const breadcrumbsArray  = [
    { key: 'key-1', text: breadCrumbLabels.dashboard[language], url: '/dashboard' },
    { key: 'key-2', text: breadCrumbLabels.offLineApplication[language], url: '/offline' }
  ]


  return (
    <>
      <OfflineForm
        data={data}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        requiredInputRefs={requiredInputRefs} 
        pageTitle={newApplicantHeadings.offLineApplication[language]}
        breadcrumbsArray={breadcrumbsArray }
        />
    </>
  );
}

