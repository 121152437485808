import { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../globalState/StateProvider';
import { resetPassword } from '../services/UserServices';
import Form from '../components/Form';
import TextInput from '../components/TextInput';
import { titleCase } from '../services/Helpers';
import 'bootstrap/dist/css/bootstrap.min.css';
import { checkInputRefValue } from '../services/FormValidation';
import { newAppMessages } from '../constants/formLabels';

function ForgotPassword() {

  const { state, dispatch } = useContext(store);
  const language = state.language
  const email = useRef();

  const handleSubmit = (data) => {
    if(checkInputRefValue(email)){
    dispatch({ type: 'SET_RESPONSE', payload: {isLoading: true } });
    resetPassword(state.form).then((response) => {

      dispatch({ type: 'SET_USER', payload: {email: state.form.email}})
      dispatch({ type: 'SET_FORM', payload: { email: '' } })
      dispatch({ type: 'SET_NOTIFICATION', payload: { status: "Success", error: undefined } })
      dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
    })
      .catch((error) => {
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
        dispatch({
          type: 'SET_NOTIFICATION', payload: {
            ...state.notifications,
            ...error
          }
        })

      });
    }else{
      alert("Please enter a valid email")
    }
  };

  const [showNotification, toggleNotification] = useState(false);
  const notification = state.notifications?.login;

  useEffect(() => {
    notification?.error && toggleNotification(true);
  }, [notification]);


  return (
    <>
      <div className="container" id="login-form">
        <img className="center flex" alt={"hf-logo"} src="/logo192.png" />
        <h1 className='text-danger mb-5 pt-3'>Password Reset</h1>
        <h3 className='text-danger'>
          {notification?.error && titleCase(`${notification.status}: ${notification.error}`)}
        </h3>
        {notification?.status == "Success"? <h2>Password reset instructions have been sent via email to {state.user.email}</h2> :

        <Form
          submit={handleSubmit}
          buttonText="Continue"
          initialValues={{
            email: ''
          }}>
          <div className="mb-3">
            <TextInput
            type="email"
            classNameInput="form-control"
            classNameLabel="form-label"
            label="Enter your email address"
            name="email"
            placeholder="email@example.com"
            innerRef={email}
            errorMessage={newAppMessages.invalidEmail[language]}
             />
          </div>
        </Form>
}
        <p>Return to Login page <a href="/login" id="">here</a></p>
      </div>
    </>
  );
}

export default ForgotPassword;