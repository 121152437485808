export const countryCodes = [
    {
    label: "United States",
    value: "1 (US)",
    code: "1"
    },
    {
    label: "Mexico",
    value: "52 (MX)",
    code: "52"
    },
    {
    label: "Afghanistan",
    value: "93 (AF)",
    code: "93"
    },
    {
    label: "Aland Islands",
    value: "358 (AX)",
    code: "358"
    },
    {
    label: "Albania",
    value: "355 (AL)",
    code: "355"
    },
    {
    label: "Algeria",
    value: "213 (DZ)",
    code: "213"
    },
    {
    label: "AmericanSamoa",
    value: "1684 (AS)",
    code: "1684"
    },
    {
    label: "Andorra",
    value: "376 (AD)",
    code: "376"
    },
    {
    label: "Angola",
    value: "244 (AO)",
    code: "244"
    },
    {
    label: "Anguilla",
    value: "1264 (AI)",
    code: "1264"
    },
    {
    label: "Antarctica",
    value: "672 (AQ)",
    code: "672"
    },
    {
    label: "Antigua and Barbuda",
    value: "1268 (AG)",
    code: "1268"
    },
    {
    label: "Argentina",
    value: "54 (AR)",
    code: "54"
    },
    {
    label: "Armenia",
    value: "374 (AM)",
    code: "374"
    },
    {
    label: "Aruba",
    value: "297 (AW)",
    code: "297"
    },
    {
    label: "Australia",
    value: "61 (AU)",
    code: "61"
    },
    {
    label: "Austria",
    value: "43 (AT)",
    code: "43"
    },
    {
    label: "Azerbaijan",
    value: "994 (AZ)",
    code: "994"
    },
    {
    label: "Bahamas",
    value: "1242 (BS)",
    code: "1242"
    },
    {
    label: "Bahrain",
    value: "973 (BH)",
    code: "973"
    },
    {
    label: "Bangladesh",
    value: "880 (BD)",
    code: "880"
    },
    {
    label: "Barbados",
    value: "1246 (BB)",
    code: "1246"
    },
    {
    label: "Belarus",
    value: "375 (BY)",
    code: "375"
    },
    {
    label: "Belgium",
    value: "32 (BE)",
    code: "32"
    },
    {
    label: "Belize",
    value: "501 (BZ)",
    code: "501"
    },
    {
    label: "Benin",
    value: "229 (BJ)",
    code: "229"
    },
    {
    label: "Bermuda",
    value: "1441 (BM)",
    code: "1441"
    },
    {
    label: "Bhutan",
    value: "975 (BT)",
    code: "975"
    },
    {
    label: "Bolivia, Plurinational State of",
    value: "591 (BO)",
    code: "591"
    },
    {
    label: "Bosnia and Herzegovina",
    value: "387 (BA)",
    code: "387"
    },
    {
    label: "Botswana",
    value: "267 (BW)",
    code: "267"
    },
    {
    label: "Brazil",
    value: "55 (BR)",
    code: "55"
    },
    {
    label: "British Indian Ocean Territory",
    value: "246 (IO)",
    code: "246"
    },
    {
    label: "Brunei Darussalam",
    value: "673 (BN)",
    code: "673"
    },
    {
    label: "Bulgaria",
    value: "359 (BG)",
    code: "359"
    },
    {
    label: "Burkina Faso",
    value: "226 (BF)",
    code: "226"
    },
    {
    label: "Burundi",
    value: "257 (BI)",
    code: "257"
    },
    {
    label: "Cambodia",
    value: "855 (KH)",
    code: "855"
    },
    {
    label: "Cameroon",
    value: "237 (CM)",
    code: "237"
    },
    {
    label: "Canada",
    value: "1 (CA)",
    code: "1"
    },
    {
    label: "Cape Verde",
    value: "238 (CV)",
    code: "238"
    },
    {
    label: "Cayman Islands",
    value: " 345 (KY)",
    code: "345"
    },
    {
    label: "Central African Republic",
    value: "236 (CF)",
    code: "236"
    },
    {
    label: "Chad",
    value: "235 (TD)",
    code: "235"
    },
    {
    label: "Chile",
    value: "56 (CL)",
    code: "56"
    },
    {
    label: "China",
    value: "86 (CN)",
    code: "86"
    },
    {
    label: "Christmas Island",
    value: "61 (CX)",
    code: "61"
    },
    {
    label: "Cocos (Keeling) Islands",
    value: "61 (CC)",
    code: "61"
    },
    {
    label: "Colombia",
    value: "57 (CO)",
    code: "57"
    },
    {
    label: "Comoros",
    value: "269 (KM)",
    code: "269"
    },
    {
    label: "Congo",
    value: "242 (CG)",
    code: "242"
    },
    {
    label: "Congo, The Democratic Republic of the Congo",
    value: "243 (CD)",
    code: "243"
    },
    {
    label: "Cook Islands",
    value: "682 (CK)",
    code: "682"
    },
    {
    label: "Costa Rica",
    value: "506 (CR)",
    code: "506"
    },
    {
    label: "Cote d'Ivoire",
    value: "225 (CI)",
    code: "225"
    },
    {
    label: "Croatia",
    value: "385 (HR)",
    code: "385"
    },
    {
    label: "Cuba",
    value: "53 (CU)",
    code: "53"
    },
    {
    label: "Cyprus",
    value: "357 (CY)",
    code: "357"
    },
    {
    label: "Czech Republic",
    value: "420 (CZ)",
    code: "420"
    },
    {
    label: "Denmark",
    value: "45 (DK)",
    code: "45"
    },
    {
    label: "Djibouti",
    value: "253 (DJ)",
    code: "253"
    },
    {
    label: "Dominica",
    value: "1767 (DM)",
    code: "1767"
    },
    {
    label: "Dominican Republic",
    value: "1849 (DO)",
    code: "1849"
    },
    {
    label: "Ecuador",
    value: "593 (EC)",
    code: "593"
    },
    {
    label: "Egypt",
    value: "20 (EG)",
    code: "20"
    },
    {
    label: "El Salvador",
    value: "503 (SV)",
    code: "503"
    },
    {
    label: "Equatorial Guinea",
    value: "240 (GQ)",
    code: "240"
    },
    {
    label: "Eritrea",
    value: "291 (ER)",
    code: "291"
    },
    {
    label: "Estonia",
    value: "372 (EE)",
    code: "372"
    },
    {
    label: "Ethiopia",
    value: "251 (ET)",
    code: "251"
    },
    {
    label: "Falkland Islands (Malvinas)",
    value: "500 (FK)",
    code: "500"
    },
    {
    label: "Faroe Islands",
    value: "298 (FO)",
    code: "298"
    },
    {
    label: "Fiji",
    value: "679 (FJ)",
    code: "679"
    },
    {
    label: "Finland",
    value: "358 (FI)",
    code: "358"
    },
    {
    label: "France",
    value: "33 (FR)",
    code: "33"
    },
    {
    label: "French Guiana",
    value: "594 (GF)",
    code: "594"
    },
    {
    label: "French Polynesia",
    value: "689 (PF)",
    code: "689"
    },
    {
    label: "Gabon",
    value: "241 (GA)",
    code: "241"
    },
    {
    label: "Gambia",
    value: "220 (GM)",
    code: "220"
    },
    {
    label: "Georgia",
    value: "995 (GE)",
    code: "995"
    },
    {
    label: "Germany",
    value: "49 (DE)",
    code: "49"
    },
    {
    label: "Ghana",
    value: "233 (GH)",
    code: "233"
    },
    {
    label: "Gibraltar",
    value: "350 (GI)",
    code: "350"
    },
    {
    label: "Greece",
    value: "30 (GR)",
    code: "30"
    },
    {
    label: "Greenland",
    value: "299 (GL)",
    code: "299"
    },
    {
    label: "Grenada",
    value: "1473 (GD)",
    code: "1473"
    },
    {
    label: "Guadeloupe",
    value: "590 (GP)",
    code: "590"
    },
    {
    label: "Guam",
    value: "1671 (GU)",
    code: "1671"
    },
    {
    label: "Guatemala",
    value: "502 (GT)",
    code: "502"
    },
    {
    label: "Guernsey",
    value: "44 (GG)",
    code: "44"
    },
    {
    label: "Guinea",
    value: "224 (GN)",
    code: "224"
    },
    {
    label: "Guinea-Bissau",
    value: "245 (GW)",
    code: "245"
    },
    {
    label: "Guyana",
    value: "595 (GY)",
    code: "595"
    },
    {
    label: "Haiti",
    value: "509 (HT)",
    code: "509"
    },
    {
    label: "Holy See (Vatican City State)",
    value: "379 (VA)",
    code: "379"
    },
    {
    label: "Honduras",
    value: "504 (HN)",
    code: "504"
    },
    {
    label: "Hong Kong",
    value: "852 (HK)",
    code: "852"
    },
    {
    label: "Hungary",
    value: "36 (HU)",
    code: "36"
    },
    {
    label: "Iceland",
    value: "354 (IS)",
    code: "354"
    },
    {
    label: "India",
    value: "91 (IN)",
    code: "91"
    },
    {
    label: "Indonesia",
    value: "62 (ID)",
    code: "62"
    },
    {
    label: "Iran, Islamic Republic of Persian Gulf",
    value: "98 (IR)",
    code: "98"
    },
    {
    label: "Iraq",
    value: "964 (IQ)",
    code: "964"
    },
    {
    label: "Ireland",
    value: "353 (IE)",
    code: "353"
    },
    {
    label: "Isle of Man",
    value: "44 (IM)",
    code: "44"
    },
    {
    label: "Israel",
    value: "972 (IL)",
    code: "972"
    },
    {
    label: "Italy",
    value: "39 (IT)",
    code: "39"
    },
    {
    label: "Jamaica",
    value: "1876 (JM)",
    code: "1876"
    },
    {
    label: "Japan",
    value: "81 (JP)",
    code: "81"
    },
    {
    label: "Jersey",
    value: "44 (JE)",
    code: "44"
    },
    {
    label: "Jordan",
    value: "962 (JO)",
    code: "962"
    },
    {
    label: "Kazakhstan",
    value: "77 (KZ)",
    code: "77"
    },
    {
    label: "Kenya",
    value: "254 (KE)",
    code: "254"
    },
    {
    label: "Kiribati",
    value: "686 (KI)",
    code: "686"
    },
    {
    label: "Korea, Democratic People's Republic of Korea",
    value: "850 (KP)",
    code: "850"
    },
    {
    label: "Korea, Republic of South Korea",
    value: "82 (KR)",
    code: "82"
    },
    {
    label: "Kuwait",
    value: "965 (KW)",
    code: "965"
    },
    {
    label: "Kyrgyzstan",
    value: "996 (KG)",
    code: "996"
    },
    {
    label: "Laos",
    value: "856 (LA)",
    code: "856"
    },
    {
    label: "Latvia",
    value: "371 (LV)",
    code: "371"
    },
    {
    label: "Lebanon",
    value: "961 (LB)",
    code: "961"
    },
    {
    label: "Lesotho",
    value: "266 (LS)",
    code: "266"
    },
    {
    label: "Liberia",
    value: "231 (LR)",
    code: "231"
    },
    {
    label: "Libyan Arab Jamahiriya",
    value: "218 (LY)",
    code: "218"
    },
    {
    label: "Liechtenstein",
    value: "423 (LI)",
    code: "423"
    },
    {
    label: "Lithuania",
    value: "370 (LT)",
    code: "370"
    },
    {
    label: "Luxembourg",
    value: "352 (LU)",
    code: "352"
    },
    {
    label: "Macao",
    value: "853 (MO)",
    code: "853"
    },
    {
    label: "Macedonia",
    value: "389 (MK)",
    code: "389"
    },
    {
    label: "Madagascar",
    value: "261 (MG)",
    code: "261"
    },
    {
    label: "Malawi",
    value: "265 (MW)",
    code: "265"
    },
    {
    label: "Malaysia",
    value: "60 (MY)",
    code: "60"
    },
    {
    label: "Maldives",
    value: "960 (MV)",
    code: "960"
    },
    {
    label: "Mali",
    value: "223 (ML)",
    code: "223"
    },
    {
    label: "Malta",
    value: "356 (MT)",
    code: "356"
    },
    {
    label: "Marshall Islands",
    value: "692 (MH)",
    code: "692"
    },
    {
    label: "Martinique",
    value: "596 (MQ)",
    code: "596"
    },
    {
    label: "Mauritania",
    value: "222 (MR)",
    code: "222"
    },
    {
    label: "Mauritius",
    value: "230 (MU)",
    code: "230"
    },
    {
    label: "Mayotte",
    value: "262 (YT)",
    code: "262"
    },
    {
    label: "Micronesia, Federated States of Micronesia",
    value: "691 (FM)",
    code: "691"
    },
    {
    label: "Moldova",
    value: "373 (MD)",
    code: "373"
    },
    {
    label: "Monaco",
    value: "377 (MC)",
    code: "377"
    },
    {
    label: "Mongolia",
    value: "976 (MN)",
    code: "976"
    },
    {
    label: "Montenegro",
    value: "382 (ME)",
    code: "382"
    },
    {
    label: "Montserrat",
    value: "1664 (MS)",
    code: "1664"
    },
    {
    label: "Morocco",
    value: "212 (MA)",
    code: "212"
    },
    {
    label: "Mozambique",
    value: "258 (MZ)",
    code: "258"
    },
    {
    label: "Myanmar",
    value: "95 (MM)",
    code: "95"
    },
    {
    label: "Namibia",
    value: "264 (NA)",
    code: "264"
    },
    {
    label: "Nauru",
    value: "674 (NR)",
    code: "674"
    },
    {
    label: "Nepal",
    value: "977 (NP)",
    code: "977"
    },
    {
    label: "Netherlands",
    value: "31 (NL)",
    code: "31"
    },
    {
    label: "Netherlands Antilles",
    value: "599 (AN)",
    code: "599"
    },
    {
    label: "New Caledonia",
    value: "687 (NC)",
    code: "687"
    },
    {
    label: "New Zealand",
    value: "64 (NZ)",
    code: "64"
    },
    {
    label: "Nicaragua",
    value: "505 (NI)",
    code: "505"
    },
    {
    label: "Niger",
    value: "227 (NE)",
    code: "227"
    },
    {
    label: "Nigeria",
    value: "234 (NG)",
    code: "234"
    },
    {
    label: "Niue",
    value: "683 (NU)",
    code: "683"
    },
    {
    label: "Norfolk Island",
    value: "672 (NF)",
    code: "672"
    },
    {
    label: "Northern Mariana Islands",
    value: "1670 (MP)",
    code: "1670"
    },
    {
    label: "Norway",
    value: "47 (NO)",
    code: "47"
    },
    {
    label: "Oman",
    value: "968 (OM)",
    code: "968"
    },
    {
    label: "Pakistan",
    value: "92 (PK)",
    code: "92"
    },
    {
    label: "Palau",
    value: "680 (PW)",
    code: "680"
    },
    {
    label: "Palestinian Territory, Occupied",
    value: "970 (PS)",
    code: "970"
    },
    {
    label: "Panama",
    value: "507 (PA)",
    code: "507"
    },
    {
    label: "Papua New Guinea",
    value: "675 (PG)",
    code: "675"
    },
    {
    label: "Paraguay",
    value: "595 (PY)",
    code: "595"
    },
    {
    label: "Peru",
    value: "51 (PE)",
    code: "51"
    },
    {
    label: "Philippines",
    value: "63 (PH)",
    code: "63"
    },
    {
    label: "Pitcairn",
    value: "872 (PN)",
    code: "872"
    },
    {
    label: "Poland",
    value: "48 (PL)",
    code: "48"
    },
    {
    label: "Portugal",
    value: "351 (PT)",
    code: "351"
    },
    {
    label: "Puerto Rico",
    value: "1939 (PR)",
    code: "1939"
    },
    {
    label: "Qatar",
    value: "974 (QA)",
    code: "974"
    },
    {
    label: "Romania",
    value: "40 (RO)",
    code: "40"
    },
    {
    label: "Russia",
    value: "7 (RU)",
    code: "7"
    },
    {
    label: "Rwanda",
    value: "250 (RW)",
    code: "250"
    },
    {
    label: "Reunion",
    value: "262 (RE)",
    code: "262"
    },
    {
    label: "Saint Barthelemy",
    value: "590 (BL)",
    code: "590"
    },
    {
    label: "Saint Helena, Ascension and Tristan Da Cunha",
    value: "290 (SH)",
    code: "290"
    },
    {
    label: "Saint Kitts and Nevis",
    value: "1869 (KN)",
    code: "1869"
    },
    {
    label: "Saint Lucia",
    value: "1758 (LC)",
    code: "1758"
    },
    {
    label: "Saint Martin",
    value: "590 (MF)",
    code: "590"
    },
    {
    label: "Saint Pierre and Miquelon",
    value: "508 (PM)",
    code: "508"
    },
    {
    label: "Saint Vincent and the Grenadines",
    value: "1784 (VC)",
    code: "1784"
    },
    {
    label: "Samoa",
    value: "685 (WS)",
    code: "685"
    },
    {
    label: "San Marino",
    value: "378 (SM)",
    code: "378"
    },
    {
    label: "Sao Tome and Principe",
    value: "239 (ST)",
    code: "239"
    },
    {
    label: "Saudi Arabia",
    value: "966 (SA)",
    code: "966"
    },
    {
    label: "Senegal",
    value: "221 (SN)",
    code: "221"
    },
    {
    label: "Serbia",
    value: "381 (RS)",
    code: "381"
    },
    {
    label: "Seychelles",
    value: "248 (SC)",
    code: "248"
    },
    {
    label: "Sierra Leone",
    value: "232 (SL)",
    code: "232"
    },
    {
    label: "Singapore",
    value: "65 (SG)",
    code: "65"
    },
    {
    label: "Slovakia",
    value: "421 (SK)",
    code: "421"
    },
    {
    label: "Slovenia",
    value: "386 (SI)",
    code: "386"
    },
    {
    label: "Solomon Islands",
    value: "677 (SB)",
    code: "677"
    },
    {
    label: "Somalia",
    value: "252 (SO)",
    code: "252"
    },
    {
    label: "South Africa",
    value: "27 (ZA)",
    code: "27"
    },
    {
    label: "South Sudan",
    value: "211 (SS)",
    code: "211"
    },
    {
    label: "South Georgia and the South Sandwich Islands",
    value: "500 (GS)",
    code: "500"
    },
    {
    label: "Spain",
    value: "34 (ES)",
    code: "34"
    },
    {
    label: "Sri Lanka",
    value: "94 (LK)",
    code: "94"
    },
    {
    label: "Sudan",
    value: "249 (SD)",
    code: "249"
    },
    {
    label: "Surilabel",
    value: "597 (SR)",
    code: "597"
    },
    {
    label: "Svalbard and Jan Mayen",
    value: "47 (SJ)",
    code: "47"
    },
    {
    label: "Swaziland",
    value: "268 (SZ)",
    code: "268"
    },
    {
    label: "Sweden",
    value: "46 (SE)",
    code: "46"
    },
    {
    label: "Switzerland",
    value: "41 (CH)",
    code: "41"
    },
    {
    label: "Syrian Arab Republic",
    value: "963 (SY)",
    code: "963"
    },
    {
    label: "Taiwan",
    value: "886 (TW)",
    code: "886"
    },
    {
    label: "Tajikistan",
    value: "992 (TJ)",
    code: "992"
    },
    {
    label: "Tanzania, United Republic of Tanzania",
    value: "255 (TZ)",
    code: "255"
    },
    {
    label: "Thailand",
    value: "66 (TH)",
    code: "66"
    },
    {
    label: "Timor-Leste",
    value: "670 (TL)",
    code: "670"
    },
    {
    label: "Togo",
    value: "228 (TG)",
    code: "228"
    },
    {
    label: "Tokelau",
    value: "690 (TK)",
    code: "690"
    },
    {
    label: "Tonga",
    value: "676 (TO)",
    code: "676"
    },
    {
    label: "Trinidad and Tobago",
    value: "1868 (TT)",
    code: "1868"
    },
    {
    label: "Tunisia",
    value: "216 (TN)",
    code: "216"
    },
    {
    label: "Turkey",
    value: "90 (TR)",
    code: "90"
    },
    {
    label: "Turkmenistan",
    value: "993 (TM)",
    code: "993"
    },
    {
    label: "Turks and Caicos Islands",
    value: "1649 (TC)",
    code: "1649"
    },
    {
    label: "Tuvalu",
    value: "688 (TV)",
    code: "688"
    },
    {
    label: "Uganda",
    value: "256 (UG)",
    code: "256"
    },
    {
    label: "Ukraine",
    value: "380 (UA)",
    code: "380"
    },
    {
    label: "United Arab Emirates",
    value: "971 (AE)",
    code: "971"
    },
    {
    label: "United Kingdom",
    value: "44 (GB)",
    code: "44"
    },
    {
    label: "Uruguay",
    value: "598 (UY)",
    code: "598"
    },
    {
    label: "Uzbekistan",
    value: "998 (UZ)",
    code: "998"
    },
    {
    label: "Vanuatu",
    value: "678 (VU)",
    code: "678"
    },
    {
    label: "Venezuela, Bolivarian Republic of Venezuela",
    value: "58 (VE)",
    code: "58"
    },
    {
    label: "Vietnam",
    value: "84 (VN)",
    code: "84"
    },
    {
    label: "Virgin Islands, British",
    value: "1284 (VG)",
    code: "1284"
    },
    {
    label: "Virgin Islands, U.S.",
    value: "1340 (VI)",
    code: "1340"
    },
    {
    label: "Wallis and Futuna",
    value: "681 (WF)",
    code: "681"
    },
    {
    label: "Yemen",
    value: "967 (YE)",
    code: "967"
    },
    {
    label: "Zambia",
    value: "260 (ZM)",
    code: "260"
    },
    {
    label: "Zimbabwe",
    value: "263 (ZW)",
    code: "263"
    }
    ]