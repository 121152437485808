import React, { useEffect, useState, useContext } from 'react';
import Breadcrumbs from '../components/BreadCrumbs';
import { breadCrumbLabels } from '../constants/formLabels';
import date from 'date-and-time';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts'
import { getStats } from '../services/Helpers';
import { store } from '../globalState/StateProvider';

export default function StatPage() {
  const { state } = useContext(store)
  const { user } = state
  const today = new Date()
  const formatedDate = date.format(today, "YYYY-MM-DD")
  const language = "english"
  const [receivedData, setReceivedData] = useState({
    daily_total: 0,
    tentatively_approved: 0,
    not_approved: 0,
    high_risk: 0,
    low_risk: 0
  })
  const userData = {
    email: user.email,
    start_date: formatedDate.toString(),
    end_date: formatedDate.toString()
  }
  const getStatsFromBackEnd = async () => {
    await getStats(userData)
      .then((resp) => setReceivedData({ ...resp.data }))
      .catch((error) => console.error("api call error: ", error))
  }

  useEffect(() => {
    getStatsFromBackEnd()
  }, [])


  const totalIncidents = receivedData.daily_total
  const approval = [
    {
      name: 'Tentitively Approved',
      applications: receivedData.tentatively_approved,
      color: "#29CC97"
    },
    {
      name: 'Not Approved',
      applications: receivedData.not_approved,
      color: "#1E1E1E"
    }
  ]
  const risk = [
    {
      name: 'High Risk',
      applications: receivedData.high_risk,
      color: "#EB5757"
    },
    {
      name: 'Medium Risk',
      applications: 0,
      color: "#F2C94C"
    },
    {
      name: 'Low Risk',
      applications: receivedData.low_risk,
      color: "#29CC97"
    }
  ];

  const breadcrumbsArray = [{ key: 'key-1', text: breadCrumbLabels.dashboard[language], url: '/dashboard' }, { key: 'key-2', text: breadCrumbLabels.newApplicant[language], url: '/stats' }]
  return (
    <div className="container-sm">
      <h2 className="form-title">Daily Stats</h2>
      <h2 className="form-title">{date.format(today, "MMMM DD YYYY")}</h2>
      <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      <div
        className="stat-page"
      >
        <div className="">
          <h2 className="flex align-items-center">
            <span className="statHeader">Total Aplications: </span>
            <span className="statHeader text-black ps-2">{totalIncidents}</span>
          </h2>
          <h3 className="statSubHeader"> Approval Status</h3>
          {approval.map((el, ind) => (
            <div
              key={ind}
              style={{ "color": el.color }}
              className="w-50 flex justify-content-between align-items-center fs-4">
              <span >{el.name}</span>
              <span className="numBox" style={{ "backgroundColor": el.color }}>{el.applications}</span>
            </div>
          ))}
          <h3 className="statSubHeader"> Risk Factor</h3>
          {risk.map((el, ind) => (
            <div
              key={ind}
              style={{ "color": el.color }}
              className="w-50 flex justify-content-between align-items-center fs-4 ">
              <span>{el.name}</span>
              <span className="numBox" style={{ "backgroundColor": el.color }} >{el.applications}</span>
            </div>
          ))}

        </div>
        <div className="flex justify-content-center align-items-center mt-5">
          <div className="flex flex-column justify-content-center align-items-center">

            <BarChart
              width={300}
              height={400}
              data={approval}
              margin={{
                top: 50,
                right: 20,
                left: 20,
                bottom: 5,
              }}
              barSize={70}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 50, right: 50 }} />
              <YAxis tick={{ stroke: 'red' }} />
              <Tooltip />
              <CartesianGrid strokeDasharray="6 6" />
              <Bar dataKey="applications" fill="#8884d8" >
                {approval.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
            <h4 className="fs-4"> Approval</h4>
          </div>
          <div className="flex flex-column justify-content-center align-items-center">

            <BarChart
              width={450}
              height={400}
              data={risk}
              margin={{
                top: 50,
                right: 20,
                left: 20,
                bottom: 5,
              }}
              barSize={70}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 50, right: 50 }} />
              <YAxis tick={{ stroke: 'red' }} />
              <Tooltip />
              <CartesianGrid strokeDasharray="6 6" />
              <Bar dataKey="applications" fill="#8884d8" >
                {risk.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
            <h4 className="fs-4"> Risk Factor</h4>
          </div>
        </div>

      </div>
    </div>
  );
}
