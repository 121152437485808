import React, {  useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import { store } from '../../globalState/StateProvider';
import './form.css';

export const FormContext = React.createContext({
  form: {}
});

const handleKeyDown = (submit, state, formName) => (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    submit(state[formName]);
  };
};

function Form(props) {
  const { children, className, submit = () => {}, formName, buttonText, isDisabled } = props;
  const { state, dispatch } = useContext(store);
  const isLoading = state.apiResponse?.isLoading ?? false;

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      <form onKeyDown={handleKeyDown(submit, state, formName, buttonText)} className={className}>
        <div>
          {children}
          <button type="button" className={`form-button flex center ${ isDisabled ? "disabled btn btn-secondary" : ""} `} onClick={() => submit(state[formName])} disabled={isLoading || isDisabled}>
            {/* {isLoading ? <ClipLoader size="25" color='#DDE2FF'/> : "Continue"} */}
            {isLoading ? <ClipLoader size="25" color='#DDE2FF'/> : buttonText}

          </button>
        </div>
      </form>
    </FormContext.Provider>
  );
}

export default Form;