import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../Form';
import { validateInput } from '../../services/FormValidation';

function SelectInput(props) {
  const {
    name,
    label,
    labelClass,
    className,
    innerRef,
    value,
    errorMessage,
    options = [],
    formName = 'form',
  } = props;


  // const { state, dispatch } = useContext(FormContext);
  const { state, dispatch } = useContext(FormContext);

  const [inputValue, setInputValue] = useState(value);
  let formValue = state[formName] && state[formName][name]
  
  useEffect(() => {
    setInputValue(value);
    value !== formValue && dispatch({
      type: 'UPDATE_FIELD',
      field: name,
      value: value,
      form: formName 
    });
  }, [value, name, formName, dispatch])

  const handleChange = e => {
    validateInput(e);
    setInputValue(e.target.value);
 
     dispatch({
      type: 'UPDATE_FIELD',
      field: e.target.name,
      value: e.target.value,
      form: formName ?? 'form'

    });
  };


  return (
    <div className="select">
      <label htmlFor={name} className={labelClass}>{label}</label>
      <select 
        name={name} 
        className={className}
        id={name}
        ref={innerRef}
        value={inputValue}
        onChange={handleChange} >
        <option value="">Select Type</option>
        {options.map(function(option){
          return (<option key={option.value+name} 
                          value={option.value}>{option.value}</option>)
        })}
      </select>
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    </div>
  )
}

export default SelectInput;