import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { store } from '../globalState/StateProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { riskClasses, riskTranslation } from '../components/RiskAssesment/RiskAssesment';
import { search } from '../services/UserServices';
import Pagination from '../components/Pagination';
import { BiSort } from 'react-icons/bi'

function AppSearchList() {
  const { state, dispatch } = useContext(store);

  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const pageNum = searchParams.get("page") ?? 1;
  const searchBy = searchParams.get("by");
  const sortBy = searchParams.get("sort");

  let apiResponse = state.apiResponse.beneficiaries;

  function handleDirectAccess() {
    try {
      if (searchBy === "refId") {
        let searchID = searchParams.get("id");
        if (searchID === 'undefined') {
          searchID = undefined;
        };
        const refIdObject = { id: searchID };
        search(refIdObject).then((response) => {
          dispatch({ type: 'SET_SEARCH', payload: {} });
          dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
          apiResponse = response.data;
        })
          .catch((error) => {
            handleError(error)
          });
      } else if (searchBy === "contactInfo") {
        let searchfirstName = searchParams.get("firstName");
        if (searchfirstName === 'undefined') {
          searchfirstName = undefined;
        };
        const firstNameObject = { firstName: searchfirstName };
        search(firstNameObject).then((response) => {
          dispatch({ type: 'SET_SEARCH', payload: {} });
          dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: { ...response.data } } });
          apiResponse = response.data;
        })
          .catch((error) => {
            handleError(error)
          });
      } else if (searchBy === "aeyonId") {
        let searchAeyonId = searchParams.get("id");
        if (searchAeyonId === 'undefined') {
          searchAeyonId = undefined;
        };
        const aeyonIdObject = { aeyonId: parseInt(searchAeyonId) };
        search(aeyonIdObject).then((response) => {
          dispatch({ type: 'SET_SEARCH', payload: {} });
          dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
          apiResponse = response.data;
        });
      };
    } catch (error) {
      handleError(error)
    }
  };

  if (!apiResponse) {
    handleDirectAccess();
  };

  const total = state?.apiResponse.beneficiaries?.total_count
    ? state?.apiResponse.beneficiaries?.total_count
    : state?.apiResponse.beneficiaries?.requests?.total_count;

  const per_page = 15;
  const navigate = useNavigate();

  function handleError(response) {
    if (response.error === "Invalid access token") {
      navigate("/login");
    }
    console.log(`ERROR`, response);
  }

  const [sortOption, setSortOption] = useState("date_of_submission");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    !sortBy && setSortOption("date_of_submission");
  }, [sortBy])
  const sortTableData = (e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    let requestParams = {};

    searchParams.forEach((value, key) => {
      requestParams[key] = value;
    });
    // replace current page number with new page
    requestParams = { ...requestParams, sort: newSortOption };

    search(requestParams)
      .then((response) => {
        dispatch({
          type: "SET_RESPONSE",
          payload: {
            beneficiaries: response.data,
          },
        });
        navigate(
          `/search?by=all&showResults=true&sort=${encodeURIComponent(
            newSortOption
          )}&page=1`
        );
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const handleSortDirection = (direction) => {
    setSortDirection(direction);
    let requestParams = {};

    searchParams.forEach((value, key) => {
      requestParams[key] = value;
    });
    requestParams = { ...requestParams, direction: direction };

    search(requestParams)
      .then((response) => {
        dispatch({
          type: "SET_RESPONSE",
          payload: {
            beneficiaries: response.data,
          },
        });
        navigate(
          `/search?by=all&showResults=true&sort=${encodeURIComponent(
            sortOption
          )}&direction=${sortDirection}&page=1`
        );
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const ChangePage = (e) => {
    const pageIndex = e.selected;
    const page = pageIndex + 1;
    let requestParams = {};

    searchParams.forEach((value, key) => {
      requestParams[key] = value;
    });
    // replace current page number with new page
    requestParams = { ...requestParams, page: page };
    search(requestParams)
      .then((response) => {
        dispatch({
          type: "SET_RESPONSE",
          payload: { beneficiaries: response.data },
        });
        const newQueryString = Object.keys(requestParams)
          .map((key) => key + "=" + requestParams[key])
          .join("&");
        navigate(`/search?${newQueryString}`);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const handleClick = async (beneficiary) => {
    //mocking a api call by setting our response state equal to what our api would call if we fetched request directly
    dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: { requests: [beneficiary] } } });
    navigate(`/applicant?id=${beneficiary?.id}`)
  }
  return (
    <div className="row align-items-start existing-applicant-form">
      <div className="col-12 mb-5">
        <h3 className="float-start">Results</h3>
        {/* <a href="#" className="float-end">View All</a> */}
        <div className="float-end">
          <label className="sort-buttons">Sort by</label>
          <select className="sort-buttons" value={sortOption} onChange={sortTableData}>
            <option value="date_of_submission">Most Recent</option>
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
            <option value="date_of_birth">Date of Birth</option>
          </select>
          <BiSort onClick={() => handleSortDirection(sortDirection === "asc" ? "desc" : "asc")} />
        </div>
      </div>

      {/*  */}
      <div className="col-12 mb-3">
        {apiResponse?.requests ? (
          <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Beneficiary Name</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Risk Level</th>
                </tr>
              </thead>
              {apiResponse.requests.map((beneficiary) => (
                <tbody key={beneficiary?.id}>
                  <tr>
                    <td className="response-user-name" onClick={() => handleClick(beneficiary)}>
                      {beneficiary?.first_name} {beneficiary?.middle_name}{" "}
                      {beneficiary?.last_name}
                    </td>
                    <td className="respone-user-dob">
                      {beneficiary?.date_of_birth}
                    </td>
                    <td className="risk-badge">
                      <span
                        className={`risk-level text-white ${riskClasses[
                          beneficiary?.validation_response?.riskLevel
                          ]
                        }`}
                      >
                        {
                          riskTranslation[
                            beneficiary?.validation_response?.riskLevel
                          ]
                        }
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <Pagination
              total={total}
              perPage={per_page}
              handlePageChange={ChangePage}
              currentPage={pageNum}
            />
          </>
        ) : (
          <div className="text-muted">No Results</div>
        )}
      </div>
    </div>
  );
}

export default AppSearchList;