import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { store } from '../globalState/StateProvider';
import { ClipLoader } from 'react-spinners';
import RiskAssessment from '../components/RiskAssesment/RiskAssesment';
import { postAppInfo } from '../services/UserServices';
// import Textarea from '../components/Textarea';
import PaymentForm from '../pages/PaymentForm';

function NewAppMessageScreen() {

  const { state, dispatch } = useContext(store);
  const navigate = useNavigate();
  const response = state.apiResponse.data;

  let displayText = "";
  let message = "";
  let description = "";
  let responseClass = "";
  let alertClass = "";

  const [isLoading, setIsLoading] = useState(false);

  if (!response?.validation_response?.errors && response?.id) {
    displayText = 'Application Saved';
    message = 'Success!';
    description = 'Risk assessment complete. View Results below';
    responseClass = 'text-success mb-3';
    alertClass = 'alert alert-success  mb-5'

  } else if (!response?.validation_response) {
    displayText = "We apologize for the inconvenience. Seems like we don't have a risk assessment. Our validation tool might be experiencing some technical difficulties.";
    message = 'Uh Oh!';
    description = "Please try your request again at a later time. If you continue to encounter this issue, please don't hesitate to reach out to our support team for further assistance"
    responseClass = 'text-danger mb-3';
    alertClass = 'alert alert-danger  mb-5'

  } else {
    displayText = response?.id ? 'Application Saved' : 'There was a error uploading your application';
    message = 'Uh Oh!';
    description = response?.id ? 'Risk assessment seems to have failed, please see response below' : 'Please try again, if issue persists. Please contact support.'
    responseClass = 'text-danger mb-3';
    alertClass = 'alert alert-danger  mb-5'

  }
  const handleAddBeneficiary = () => {
    dispatch({ type: 'CLEAR_FORM', payload: {} });
    navigate('/new-applicant');



    // //Hook this up 
    // const confirmed = state.apiResponse.data.notes !== state.form.notes && window.confirm("Save Notes?");
    // if (confirmed) {
    //   //save response 
    //   console.log("Saving");

    //   //navigate
    //   console.log("Navigating")
    //   // navigate('/new-applicant');
    // }
    // //clear on cancel
    // navigate('/new-applicant');
    // dispatch({
    //   type: 'UPDATE_FIELD',
    //   field: 'notes',
    //   value: '',
    //   form: 'form'
    // });

  }
  const handlePaymentForm = () => {
    //follow same pattern as above. 
    navigate(`/payment-form?id=${response?.id}`);

  }
  const handleDash = () => {
    //follow same pattern as above. 
    navigate('/dashboard');

  }
  const renderErrors = (errors) => {
    let text = ''
    errors.map((e) => {
      console.log(e)
      text = text + e + `\n`
    })
    return text
  }

  const handleRetry = () => {
    setIsLoading(true);
    const finalRequest = { ...state.form };

    postAppInfo(finalRequest).then((response) => {
      console.log(response)
      dispatch({ type: 'SET_RESPONSE', payload: { ...response, isLoading: false } });
      setIsLoading(false);

      navigate('/new-applicant-response');
    })
      .catch((error) => {
        //Some errors are coming back as array some as error object. This handles both
        let displayError = Array.isArray(error) ? renderErrors(error) : error.error
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
        alert("Uh-oh, the following error occurred: \n" + displayError)
      });
  }

  if (isLoading) {
    return (
      <div className="container-sm text-center bg-body p-5 mt-5">
        <ClipLoader size="50" color='#DDE2FF' />
        <br />
        <h1>Uploading Application</h1>
      </div>
    )
  }
  return (
    <div className="container-sm text-center bg-body p-5 mt-5">
      <h2 className={responseClass}>{message}</h2>
      <div className={alertClass} role="alert">
        {displayText}
        <br />
        <br />
        {description}
      </div>
      {response?.id && <RiskAssessment response={response} />}
      <br />
      {/* <div className="col-12 mb-3 left-text">
        <Textarea
          className="form-control risk-assesment-ta"
          label="notes"
          name="notes"
          placeholder=""
        />
        <br />
      </div> */}
      <PaymentForm showHeader={false}></PaymentForm>
      {response?.id ? (<span className="flex center half col-flex">
        <span className="btn btn-secondary p-3" onClick={handleAddBeneficiary} role="button">Add another beneficiary</span>
        <br />
        {/* <span className="btn btn-primary p-3" onClick={handlePaymentForm} role="button">Continue to Payment Form</span> */}
      </span>) : (
        <>
          <h3>
            {`Beneficiary: ${state.form.firstName}  ${state.form.lastName}`}
          </h3>
          <br />
          <br />
          <span className="flex center half col-flex">
            {response?.id && <span className="btn btn-secondary p-3" onClick={handleRetry} role="button">Try Again</span>}            <br />
            <span className="btn btn-primary p-3" onClick={handleDash} role="button">Dashboard</span>
          </span>
        </>
      )}
    </div>
  );
}

export default NewAppMessageScreen;