
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { offlinePost } from '../services/offlinepost';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../components/BreadCrumbs';
import useNavigatorOnline from "use-navigator-online";
import { database } from '../globalState/DexieState';
import Application from '../components/OfflineForm/Application';
import { RiRefreshLine } from 'react-icons/ri';


export default function OfflineAppsDash() {
  const [offlineData, setOfflineData] = useState()
  const [loading, setLoading] = useState(false)
  const [errorState, setError] = useState('')
  const [responseState, setResponseState] = useState([])
  const { isOnline, backOnline, backOffline } = useNavigatorOnline();
  const navigate = useNavigate();

  const getData = async () => {
    const data = await database.toArray()
    if (data.length > 0) {
      setOfflineData(data)
    }

    else {
      setOfflineData([])
    }
  }

  useEffect(() => {
    getData()
  }, [backOnline, backOffline,])


  const getAppFromIndexDB = (id) => {
    navigate(`/${id}`)
  }

  const renderCounter = () => {
    return errorState.length !== 0 || responseState.length !== 0 ? <p> {`${errorState.length + responseState.length} processed applications.`}</p> : <p> {`${offlineData?.length} pending application.`} </p>
  }

  const renderLoader = () => {
    return (<div className="d-flex w-100 h-100 justify-content-center align-items-center">
      <ClipLoader size="68px" cssOverride={{ color: "#DDE2FF" }} />
    </div>)
  }
  const renderResponses = () => {
    return (
      <div>
        <h3> {`${errorState.length} Failed`}, {`${responseState.length} Succesful `}</h3>
        <br />
        {errorState && errorState?.map((err, idx) => (
          <div key={`${idx}-error`}>
            <Application app={JSON.parse(err.error.config.data)} idx={`${idx}-error-child`} isConfirmation={true} error={err.error.response.data} />
          </div>
        ))}
        {responseState?.map((app, idx) => (
          <div key={`${idx}-success`}>
            <Application idx={`${idx}-success-child`} app={app} isOnline={isOnline} isConfirmation={true} />
          </div>
        ))}



        <br />
        <h4>Please refresh to view and edit unsuccessful records</h4>
        <br />
        <button onClick={() => window.location.reload()} className='btn btn-warning flex center'>
          Refresh <RiRefreshLine size={24} />
        </button>
      </div>
    )
  }
const renderDbApplications =()=>{
  return offlineData?.map((app, idx) => (
    <div key={idx}>
    <Application idx={idx+"child"} app={app} isOnline={isOnline} />
    </div>
  ))
}
  const submitAllOfflineForms = async () => {
    setLoading(true)
    const dataToPost = offlineData.map(rec => offlinePost(rec));
    // Had to rework this a bit, really added some steps, axios.all would stop if a request errored out. and would leave requests in the queue even though they processed correctly.
    
    //Reworked it to map and resolve all promises and with that also build up an array of responses and errors this was done in order to address the following issue:

    //Next challenge was the window.reloads happened before the client could remove requuests that processed succesfully (Usually would be able to delete one before the reload) hence why you see a bunch of async stuff, some probably not actually doing anything but such is life 


    // To get past the window reload used the built up arrays to re-display said requests with some visual feedback to the user
    const promises = [...dataToPost]
    const promisesResolved = promises.map(promise => promise.catch(error => ({ error })))
    let errorArray = [];
    let responseArray = [];

    function checkFailed(then) {
      return function (responses) {
        responses.forEach(async response => {
          // console.log("R", response)
          if (response.error) {
            errorArray.push(response)
          }
          if (response.status === 201) {
            responseArray.push(response.data)

            // console.log("status: ", response.data.offline_id)
            await database.where("offline_id").equals(response.data.offline_id).first(async entry => {
              // console.log('entry')
              if (entry) {
                const id = entry.id;
                console.log(id)
                await database.delete(id).then(() => {
                  console.log('Entry deleted successfully');
                })
                  .catch((err) => {
                    console.error('Error deleting entry', err);
                  });
              }
            })
          }
          return then(response);
        });
        //Once mapped through our responses and created our two arrays, setting them in state
        setResponseState(responseArray);
        if (errorArray.length > 0) {
          setError(errorArray)
          throw errorArray
        }
      }
    }
    try {

      await axios.all(promisesResolved)
        .then(checkFailed(response => (response)))
        .then(() => setLoading(false))
        .catch((err) => {
          setLoading(false)
        });
    } catch (error) {
      console.log("TRY ERROR", error)
      setLoading(false)
    }
  }


  const breadcrumbsArray =
    [{ key: 'key-1', text: 'Dashboard', url: '/dashboard' },
    { key: 'key-2', text: 'Offline Panel', url: '/offline-applications-dashboard' }]
  return (
    <>
      <div className="container-sm mt-5">
        <h2 className="form-title pt-4">Applications Recorded Offline</h2>
        <Breadcrumbs breadcrumbs={breadcrumbsArray} />

        <div className={`existing-applicant-form d-flex flex-column ${loading ? "justify-content-center" : "justify-content-between"} viewHeight`}>
        {renderCounter()}
          {loading ?
            renderLoader() :
            <div className={'scroll-container'}>
              {errorState?.length !== 0 || responseState.length > 0 ? renderResponses():renderDbApplications()}
            </div>
          }

          {!loading && <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <button
              className="btn btn-primary w-50"
              disabled={!isOnline || errorState.length !==0 || responseState.length !==0 }
              onClick={() => submitAllOfflineForms()}>
              Upload All Applications
            </button>
            <button
              type="button"
              className="btn btn-secondary w-50 mt-2"
              onClick={() => navigate('/')}> Back
            </button>
          </div>}
        </div>
      </div>
    </>
  );
}
