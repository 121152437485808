
import React, { createContext, useReducer } from 'react';
import { InitialScreeningForm } from '../constants/InitialScreeningForm';
const initialState = { form:{...InitialScreeningForm}, user: {}, apiResponse: {}, search: {}, language: 'english' };

const store = createContext(initialState);
const { Provider } = store;
const StateProvider = ({ children }) => {


    const [state, dispatch] = useReducer((state, action) => {
        const payload = { ...action.payload }

        switch (action.type) {
            case 'SET_FORM':
                return { ...state, form: {...state.form,...payload }};
            case 'CLEAR_FORM':
                return { ...state, form: {...InitialScreeningForm }};
            case 'SET_SEARCH':
                return { ...state, search: {...payload}};
            case 'UPDATE_FIELD':

            let formObject ={}

            formObject[action.form] = { 
                ...state[action.form], 
                [action.field]: action.value
            }
                return {
                    ...state,
                    ...formObject                    
                };
            case 'SET_USER':
                return { ...state, user: { ...payload } };
            case 'SET_RESPONSE':
                return { ...state, apiResponse: { ...payload } };
            case 'SET_NOTIFICATION':
                return { ...state, notifications: { login: { ...payload } } };
            case 'SET_LANGUAGE':
                return { ...state, language: action.payload };
            default:
                return state;
        };
    }, initialState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider }
