import { AppRoutes } from './routes/Routes';
import './App.css';
import { StateProvider } from './globalState/StateProvider';
import OfflineToast from './components/OfflineToast'

function App() {

  return (
    <StateProvider>
      <div className="App">
       <OfflineToast />
        <AppRoutes />
      </div>
    </StateProvider>
  );
}

export default App;



