import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { store } from '../globalState/StateProvider';
import { login } from '../services/UserServices';
import Form from '../components/Form';
import TextInput from '../components/TextInput';
import { titleCase } from '../services/Helpers';
import 'bootstrap/dist/css/bootstrap.min.css';

function Login() {
  const { state, dispatch } = useContext(store);

  const navigate = useNavigate();

  const handleSubmit = (data) => {
    dispatch({ type: 'SET_RESPONSE', payload: { isLoading: true } });
    login(state.form)
      .then((response) => {
        navigate('/dashboard');
        dispatch({ type: 'SET_USER', payload: { email: state.form.email } });
        dispatch({ type: 'SET_FORM', payload: { email: '', password: '' } });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: { status: 'Success', error: undefined },
        });
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
      })
      .catch((error) => {
        dispatch({ type: 'SET_RESPONSE', payload: { isLoading: false } });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            ...state.notifications,
            ...error,
          },
        });
      });
  };

  const [showNotification, toggleNotification] = useState(false);
  const notification = state.notifications?.login;

  useEffect(() => {
    notification?.error && toggleNotification(true);
  }, [notification]);

  return (
    <>
      <div className="container" id="login-form">
        <img className="center flex" alt={"hf-logo"} src="/logo192.png" />
        <h2 className="text-center">
          Hispanic Federation's <br />
          FFWR Intake App
        </h2>
        <p id="description">Enter your email and password below to login</p>
        <h3 className="text-danger">
          {showNotification && titleCase(`${notification.status}: ${notification.error}`)}
        </h3>

        <Form
          submit={handleSubmit}
          buttonText="Login"
          initialValues={{
            email: '',
            password: '',
          }}
        >
          <div className="mb-3">
            <TextInput
              classNameInput="form-control"
              classNameLabel="form-label"
              label="Email"
              name="email"
              placeholder="Email address"
            />
            <a
              className="float-end mt-3"
              id="forgot-password"
              href="forgotten-password"
            >
              Forgot password?
            </a>
          </div>
          <div className='mb-3'>
            <TextInput
              type='password'
              classNameInput='form-control'
              classNameLabe='form-label'
              label='Password'
              name='password'
              placeholder='Password'
            />
          </div>
        </Form>
        <p>
          Don't have account?
          <a href="www.codethedream.org" id="">
            Request One
          </a>
        </p>
      </div>
    </>
  );
}

export default Login;
