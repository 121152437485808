import React, { useState, useContext } from 'react';
import { store } from '../../globalState/StateProvider';
import { Link, useNavigate } from 'react-router-dom';
import { search } from '../../services/UserServices';

export const riskTranslation = {
    0: 'none',
    1: 'high',
    2: 'mid-high',
    3: 'mid',
    4: 'mid-low',
    5: 'low',
    6: 'lowest'

}
export const riskReason = {

    0: ["N/A"],
    1: ["First Name", "Middle Name", "Last Name", "Suffix", "Date of Birth", "Current Residency Zip", "Contact Info", "Profession", "Name of Employer", "Intake Method"],
    2: ["First Name", "Middle Name", "Last Name", "Suffix", "Date of Birth", "Current Residency Zip", "Contact Info"],
    3: ["First Name", "Middle Name", "Last Name", "Date of Birth"],
    4: ["Last Name", "Date of Birth", "Current Residency Zip"],
    5: ["First Name", "Middle Name", "Date of Birth", "Current Residency Zip"],
    6: ["First Name", "Middle Name", "Last Name"]
}
export const riskClasses = {

    0: 'bg-secondary',
    1: 'bg-danger',
    2: 'bg-orange',
    3: 'bg-warning',
    4: 'bg-yellowgreen',
    5: 'bg-success',
    6: 'bg-success'
}
const calculateAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}
function RiskAssessment({response}) {

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);



    function handleError(response) {
        if (response.error === "Invalid access token") {
          navigate('/login');
        }
        console.log(`ERROR`, response);
      }

    const handleClick = (item, e) => {
        e.preventDefault();

        const aeyonID = item;
                navigate(`/applicant?aeyon_id=${aeyonID}`);

      };

const renderErrors = (data) => {
    for (const [key, value] of Object.entries(data.validation_response.errors)) {
        return (
            <>{`${key} : ${value[0]} `}</>
        )
    }
}
const FailedPost = (data) => {


    return (data.validation_response?.errors ? <>
        <h3>ID:  {data.id}</h3>
        <h3> {data.first_name} {data.middle_name} {data.last_name}</h3>
        <br />
        <h3>Errors:</h3>
        {renderErrors(data)}
    </>: <><h3>No Risk Assessment found </h3> {`${data.validation_response?.message} : Aeyon IDs - ${data.validation_response?.duplicates}`} </>)
}

const Success = (data) => {



    const beneficiary = data.validation_response.beneficiary;
    const riskLevel = data.validation_response.riskLevel;



    const internalMatches = data.grouped_matches.internal_match_ids;
    const externalMatches = data.grouped_matches.external_matches;




    
    
    return (
        <>
            <h3>ID:  {beneficiary.externalId}</h3>
            <h3> {beneficiary.firstName} {beneficiary.middleName} {beneficiary.lastName} ({beneficiary.id})</h3>
            <br />
            <h3>Risk Assessment: </h3>
            <span className="flex mt-3">
                <span>
                    Duplication Risk:

                    <span className="risk-badge">
                        {/* <span className={`risk-level text-white  bg-danger`}> */}
                        <span className={`risk-level text-white  ${riskClasses[riskLevel]}`}>
                            {riskTranslation[riskLevel]}
                        </span>
                    </span>
                </span>

                <span className="right">
                    Other Risk:
                    {
                        (beneficiary.hasPrimaryPhoneRisk || beneficiary.hasSecondaryPhoneRisk ||
                            beneficiary.hasPrimaryEmailRisk || beneficiary.hasSecondaryEmailRisk) &&
                        <span className="risk-badge">
                            <small className={`risk-level text-white ${riskClasses[`${response?.val}`]} bg-danger`}>
                                Likely Fraud
                            </small>
                        </span>
                    }



                    {beneficiary.isInvalidBirthdate && <span className="risk-badge">
                        <small className="risk-level text-white bg-danger">
                            {calculateAge(beneficiary.dateOfBirth) > 90 ? 'Age > 90' : 'Age<10'}
                        </small>
                    </span>
                    }
                    {beneficiary.isInvalidZipcode &&

                        <span className="risk-badge">
                            <small className="risk-level text-white bg-orange">
                                Invalid Zipcode
                            </small>
                        </span>
                    }
                    {(beneficiary.isInvalidPrimaryAreaCode || beneficiary.isInvalidSecondaryAreaCode) &&


                        <span className="risk-badge">
                            <small className="risk-level text-white bg-orange">
                                Invalid area code
                            </small>
                        </span>
                    }
                </span>
            </span>
            <br />
            <div className='flex'>

            <div className='half'>
                <strong>Potential Matches</strong> :  {response?.grouped_matches?.total}
                <br />
                <br />
                <div className="row mb-3">
                    <div className="col ps-5 pe-5">
                    <p><strong>External Matches</strong> :  {response?.grouped_matches?.external_matches?.length}</p>
                    <ul className="list-group">
                    {externalMatches.slice(0, 4).map((item, index) => (
                        <li key={index} className="list-group-item">
                        {item.org_name} ({item.poc})
                        </li>
                    ))}
                    </ul>
                    {response?.grouped_matches?.external_matches?.length > 4 && (
                    <div className="mt-3">
                        {isOpen && (
                        <div className="mt-3">
                            <ul className="list-group">
                            {response?.grouped_matches?.external_matches?.slice(4).map((item, index) => (
                                <li key={index + 4} className="list-group-item">
                                {item.org_name} ({item.poc})
                                </li>
                            ))}
                            </ul>
                        </div>
                        )}
                        <button className="btn btn-primary mt-3" onClick={toggle}>
                        {isOpen ? 'Hide' : 'Show More'}
                        </button>
                        </div>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col ps-5 pe-5">
                        <p><strong>Internal Matches</strong> :  {response?.grouped_matches?.internal_match_ids?.length}</p>
                        <ul className="list-group">
                            {internalMatches.slice(0, 4).map((item, index) => (
                            <li key={index} className="list-group-item"><Link onClick={(e) => handleClick(item, e)}>{item}</Link></li>
                            ))}
                        </ul>
                        {internalMatches.length > 4 && (
                            <div className="mt-3">
                            {isOpen && (
                            <div className="mt-3">
                                <ul className="list-group">
                                    {internalMatches.slice(4).map((item, index) => (
                                    <li key={index + 4} className="list-group-item"><Link onClick={(e) => handleClick(item, e)}>{item}</Link></li>
                                    ))}
                                </ul>
                            </div>
                            )}
                            <button className="btn btn-primary mt-3" onClick={toggle}>
                            {isOpen ? 'Hide' : 'Show More'}
                            </button>
                        </div>
                        )}
                    </div>
                </div>
                <br />
                <br />
                <strong>Other Risks</strong>
                <br />
                <ul>
                    {beneficiary.hasPrimaryEmailRisk &&
                        <li>Primary email ( {beneficiary.primaryEmail}) flagged for known fraud risk </li>}


                    {beneficiary.hasPrimaryPhoneRisk &&
                        <li>Primary number ( {beneficiary.primaryPhone}) flagged for known fraud risk </li>}


                    {beneficiary.hasSecondaryEmailRisk &&
                        <li>Secondary email ( {beneficiary.secondayEmail}) flagged for known fraud risk </li>}


                    {beneficiary.hasASecondaryPhoneRisk &&
                        <li>Secondary number ( {beneficiary.secondaryPhone}) flagged for known fraud risk </li>}


                    {/* {response.beneficiary.isInvalidBirthdate}   */}
                    {/* <li> "D.O.B. of {response.date_of_birth} would make this beneficiary {((Time.zon - response.date_of_birth.to_time) / 1.year.seconds).floor}" </li> */}

                    {beneficiary.isInvalidZipcode &&
                        <li> "Invalid Zipcode ({beneficiary.zipcode})" </li>}

                    {beneficiary.isInvalidPrimaryAreaCode &&
                        <li> "Invalid Primary area code ({beneficiary.primaryPhone})" </li>}

                    {beneficiary.isInvalidSecondaryAreaCode &&
                        <li> "Invalid Secondary area code ({beneficiary.secondaryPhone})" </li>}

                </ul>
            </div>
            <div className='half'>
            <strong> Matched on:  </strong> <br />
            <ul>
                {riskReason[riskLevel].map((item) => {
                    return <li key={item+riskLevel}>{item} </li>
                })}
            </ul>
            </div>
            </div>
        </>

    )
}
    if (!response || response.length === 0) {
        return (<h2>{"Request Not Found"}</h2>)
    } else {
        return (
            <div className='' style={{ textAlign: 'left' }}>
                {response.validation_response?.errors || !response.validation_response || response.validation_response.message ? FailedPost(response) : Success(response)}
            </div>
        )
    }
}
export default RiskAssessment;