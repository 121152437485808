import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../Form';
import { validateInput } from '../../services/FormValidation';

function TextInput(props) {
  const {
    label, 
    type, 
    name,
    classNameInput,
    classNameLabel,
    innerRef,
    placeholder,
    value,
    formName,
    errorMessage
  } = props;

  const {  dispatch } = useContext(FormContext);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
    dispatch({
      type: 'UPDATE_FIELD',
      field: name,
      value: value,
      form: formName ?? 'form'
    });
  }, [value, dispatch, formName, name])

  const handleChange = async (e) => {
    setInputValue(e.target.value);
    validateInput(e);

    await dispatch({
      type: "UPDATE_FIELD",
      field: e.target.name,
      value: e.target.value,
      form: formName ?? "form",
    });
  };

  return (
    <div>
      <label htmlFor={name} className={classNameLabel}>{label}</label>
      <input
        type={type}
        className={classNameInput}
        name={name}
        id={name}
        placeholder={placeholder}
        value={inputValue}
        ref={innerRef}
        onChange={handleChange}
      />
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    </div>
  )
}

export default TextInput;