import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../Form';
import { validateInput } from '../../services/FormValidation';

function MultiSelectInput(props) {
  const {
    name,
    label,
    labelClass,
    className,
    innerRef,
    value = [],
    options = [],
    formName = 'form',
  } = props;


  // const { state, dispatch } = useContext(FormContext);
  const { state, dispatch } = useContext(FormContext);

  const [inputValue, setInputValue] = useState(value);

  let formValue = state[formName] && state[formName][name];

  useEffect(() => {
    setInputValue(value);
    dispatch({
      type: 'UPDATE_FIELD',
      field: name,
      value: Array.isArray(value) ? value : value && value.split(','),
      form: formName 
    });
  }, [value, name, formName, dispatch])

  const MAX_SELECT = 4;
  const addToArray = (array, currentSelection) => {
    return array.length < MAX_SELECT ? [...array, ...currentSelection] : [...array]
  }
  const removeFromArray = (array, currentSelection) => {
    return array.filter(n => n !== currentSelection)
  }
  const handleChange = e => {
    validateInput(e);
    setInputValue(e.target.value);
    let currentSelection = [e.target.value];

    let formArray = Array.isArray(formValue) ? formValue : [];

    dispatch({
      type: 'UPDATE_FIELD',
      field: e.target.name,
      value: formArray.find(n => n === e.target.value) ? removeFromArray(formArray, e.target.value) : addToArray(formArray, currentSelection),
      form: formName ?? 'form'

    });
  };


  return (
    <div className="select">
      <label htmlFor={name} className={labelClass}>{label}</label>
      <select
        name={name}
        className={className}
        id={name}
        ref={innerRef}
        value={inputValue}
        onChange={handleChange}
        multiple={true} >
        {options.map(function (option) {
          return (<option key={option.value + name}
            value={option.value}>{option.value}</option>)
        })}
      </select>

    </div>
  )
}

export default MultiSelectInput;