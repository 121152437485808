import { useContext } from 'react';
import { FormContext } from '../Form';

function CheckBox(props) {
  const {
    label,
    name,
    classNameInput,
    innerRef,
    placeholder,
    formName,
    value
  } = props;

  const { dispatch } = useContext(FormContext);
  const handleChange = e => {
    dispatch({
      type: 'UPDATE_FIELD',
      field: e.target.name,
      value: e.target.checked,
      form: formName ?? 'form'
      
    });
  };

  return (
    <div className="form-check">
      <input type="checkbox"
        className={classNameInput}
        name={name}
        id={name}
        placeholder={placeholder}
        // value={inputValue}
        ref={innerRef}
        onChange={handleChange}
        checked={value}
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {label}    </label>
    </div>
  )
}

export default CheckBox;