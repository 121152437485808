
import { useContext, useEffect, useCallback } from 'react';
import { store } from '../globalState/StateProvider';
import { fetchBeneficiary } from '../services/UserServices';
import { useNavigate } from "react-router-dom";

const benDetails = (beneficiaryDetails) => {
  return (
    <div className="row align-items-start existing-applicant-form">
      <h3 >
        Beneficiary Details
      </h3>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.first_name ? beneficiaryDetails.first_name : '-'}</p>
        <p className="applicant-label">First Name</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.street_or_PO_box ? beneficiaryDetails.street_or_PO_box : '-'}</p>
        <p className="applicant-label">Street/P.O. Box</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.middle_name ? beneficiaryDetails.middle_name : '-'}</p>
        <p className="applicant-label">Middle Name(s)</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.city ? beneficiaryDetails.city : '-'}</p>
        <p className="applicant-label">City</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.last_name ? beneficiaryDetails.last_name : '-'}</p>
        <p className="applicant-label">Last Name(s)</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.state ? beneficiaryDetails.state : '-'}</p>
        <p className="applicant-label">State</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.suffix ? beneficiaryDetails.suffix : '-'}</p>
        <p className="applicant-label">Suffix</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.zip_code ? beneficiaryDetails.zip_code : '-'}</p>
        <p className="applicant-label">Zip Code</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.profession ? beneficiaryDetails.profession : '-'}</p>
        <p className="applicant-label">Profession</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.code ? beneficiaryDetails.code : '-'}</p>
        <p className="applicant-label">SOC/NAICS Code</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.primary_country_code ? beneficiaryDetails.primary_country_code : '-'}</p>
        <p className="applicant-label">Primary Country Code</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.secondary_country_code ? beneficiaryDetails.secondary_country_code : '-'}</p>
        <p className="applicant-label">Secondary Country Code</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.primary_phone ? beneficiaryDetails.primary_phone : '-'}</p>
        <p className="applicant-label">Primary Phone</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.secondary_phone ? beneficiaryDetails.secondary_phone : '-'}</p>
        <p className="applicant-label">Secondary Phone</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.primary_email ? beneficiaryDetails.primary_email : '-'}</p>
        <p className="applicant-label">Primary Email</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.secondary_email ? beneficiaryDetails.secondary_email : '-'}</p>
        <p className="applicant-label">Secondary Email</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.intake_method ? beneficiaryDetails.intake_method : '-'}</p>
        <p className="applicant-label">Intake Method</p>
      </div>
      <div className="col-6 mb-3">
        <p className="applicant-info">{beneficiaryDetails.referral_method ? beneficiaryDetails.referral_method : '-'}</p>
        <p className="applicant-label">How did beneficiaryDetails hear about our program</p>
      </div>
      <div className="col-6 mb-3">
        <strong>Status</strong> :  {beneficiaryDetails.status}

      </div>
      <div className="col-6 mb-3">
        <strong>Notes</strong> :  {beneficiaryDetails.notes}

      </div>

      <div className="col-12 mb-3">
        <h3 >
          Supporting Documents
        </h3>
        <br />
        <div className='flex flex-wrap'>
          {beneficiaryDetails?.supporting_docs &&
            beneficiaryDetails.supporting_docs
              // .filter((docs) => docs.doc_type === "Gift Card Proxy ID")
              .map((docs) => (
                <div
                  key={docs.id}
                  className="card support-docs m-3"
                // onClick={() => handleImageModal(docs)}
                >
                  <img className="m-3" src={docs.url} alt={docs.doc_type} />
                  <div className="card-body">
                    <h5 className="card-title">{docs.doc_type}</h5>
                  </div>
                  <div className="card-footer">
                    <small className="text-body-secondary">
                      Uploaded on {docs.created_at}
                    </small>
                  </div>
                </div>
              ))}
          <br/>
          <a className="flex mx-auto btn btn-primary p-3" href={`/applicant?id=${beneficiaryDetails.id}`} role="button">Add Supporting Docs</a>
        </div>
      </div>
    </div>
  )
}

function FinalScreen() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);

  const urlParams = new URLSearchParams(window.location.search);
  const applicantId = urlParams.get('id');

  const handleFetchBeneficiary = useCallback(async () => {
    function handleError(response) {
      if (response.error === "Invalid access token") {
        navigate('/login');
      }
      console.log(`ERROR`, response);
    }
    try {
      await fetchBeneficiary({ id: applicantId }).then((response) => {
        dispatch({ type: 'SET_SEARCH', payload: {} });
        dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
      })
        .catch((error) => {
          handleError(error)
        });
    } catch (error) {
      handleError(error);
    }
  }, [dispatch, applicantId, navigate])
  useEffect(() => {
    !state?.apiResponse?.beneficiaries?.id && handleFetchBeneficiary();
  }, [applicantId, handleFetchBeneficiary, state?.apiResponse?.beneficiaries?.id]);

  let displayText = "";
  let message = "";
  let alertClass = "";
  let responseClass = "";

  //This conditional was start of this component handle error displaying. 
  //Seems like there are other codes that where unaccounted for and was set to alway shit this {|| true} . Should come back to it. 
  if (state.apiResponse.status == 201 || true) {
    displayText = 'Application Saved';
    message = 'Success!';
    alertClass = 'alert alert-success  mb-5';
    responseClass = 'text-success mb-3';
  }
  if (!state.apiResponse?.beneficiaries?.id) {
    displayText = 'There was an error. Please try again';
    message = 'Uh Oh!';
    alertClass = 'alert alert-danger  mb-5'
    responseClass = 'text-danger mb-3';
  }
  return (
    <div className="container text-center bg-body p-5 mt-5">
      <h2 className={responseClass}>{message}</h2>
      <div className={alertClass} role="alert">
        {displayText}
      </div>
      <br />
      <div>
        {state?.apiResponse?.beneficiaries?.id && benDetails(state?.apiResponse.beneficiaries)}
      </div>
      <br />
      <span className="flex center half col-flex">
        {/* <a className="btn btn-secondary p-3" href={`/edit-applicant?id=${applicantId}`} role="button">Edit beneficiary</a> */}
        {/* <br /> */}
        <a className="btn btn-secondary p-3" href="/new-applicant" role="button">Add another beneficiary</a>
        <br />
        {!state.apiResponse.id &&
          <a className="btn btn-primary p-3" href="/search?by=all&showResults=true&sort=date_of_submission&direction=asc&page=1" role="button">Search Beneficiaries</a>
        }
        <br />
      </span>

    </div>
  );
}

export default FinalScreen;