import { getTodaysDate } from "./Helpers"
import axios from "axios";

export const offlinePost = async (form) => {
  const submissionDate = getTodaysDate();
  const zip_code = parseInt(form.currentZip)
  const options = {
    url: `${process.env.REACT_APP_API_URL}api/requests/`,
    method: "POST",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
      "Content-Type": "application/json"
    },
    data: {
      date_of_submission: submissionDate,
      first_name: form.firstName,
      middle_name: form.middleNames,
      last_name: form.lastName,
      suffix: form.suffix,
      street_or_PO_box: form.address,
      city: form.city,
      state: form.state ?? null,
      zip_code: zip_code,
      date_of_birth: form.dob,
      primary_phone: form.primaryPhone ?? null ,
      secondary_phone: form.secondaryPhone ?? null,
      primary_email: form.primaryEmail ?? null ,
      secondary_email: form.secondaryEmail ?? null, 
      primary_country_code: form.primary_country_code,
      secondary_country_code: form.secondary_country_code,
      employer_name: form.employerName,
      profession: form.profession,
      code: form.code,
      proof_of_identity: form.idProofType,
      proof_of_employment: form.employmentProofType,
      idProofFile: form.idProofFile,
      employmentProofFile: form.employmentProofFile,
      hf_subscribed: form.hf_subscribed,
      signatureFile: form.signature,
  // Hard coded fields for now 
      intake_method:  "In-Person - tablet/mobile device",
      referral_method:  "None",
      status: 0,
      notes: "",
      offline_id: form.offline_id
  //Missing payment related fields
      // proxyIdFile: ""
      // card_proxy: "",
      // bank_name: "",
      // ach_routing: "",
      // ach_account: "",
    }
  }

 return axios(options)
  
}

