import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { store } from '../globalState/StateProvider';
import {  search } from '../services/UserServices';
import Form from '../components/Form';
import TextInput from '../components/TextInput';
import AppSearchList from './AppSearchList';
import 'bootstrap/dist/css/bootstrap.min.css';
import HfBanner from '../components/HfBanner';
import Breadcrumbs from '../components/BreadCrumbs';


function UpdateAppSearch() {
  const { state, dispatch } = useContext(store);
  const [selectedOption, setSelectedOption] = useState('primary_phone');

  const navigate = useNavigate();
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const searchForm = searchParams.get("by");
  const showResults = searchParams.get("showResults");
  const inputValue =
    selectedOption === "primary_phone"
      ? state.search[selectedOption]
      : state.search[selectedOption];

  function handleError(response) {
    if (response.error === "Invalid access token") {
      navigate('/login');
    };
    console.log(`ERROR`, response);
  }
  const handleAllApps = () => {
    search({}).then((response) => {
      dispatch({ type: 'SET_SEARCH', payload: {} });
      dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: response.data } });
      navigate(`/search?by=all&showResults=true&page=1`);
    }).catch((error) => {
      handleError(error)
    });
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitContact = () => {
    search(state.search)
      .then((response) => {
        dispatch({ type: "SET_SEARCH", payload: {} });
        dispatch({
          type: "SET_RESPONSE",
          payload: { beneficiaries: { ...response.data } },
        });
        navigate(`/search?by=contactInfo&${selectedOption}=${inputValue}&showResults=true`);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleSubmitRef = (data) => {
    dispatch({ type: 'SET_RESPONSE', payload: { isLoading: true } });
    search(state.search).then((response) => {
      dispatch({ type: 'SET_SEARCH', payload: {} });
      dispatch({ type: 'SET_RESPONSE', payload: { beneficiaries: {...response.data }, isLoading: false } });
      navigate(`/search?by=refId&id=${data.id}&showResults=true`);

    })
      .catch((error) => {
        handleError(error)
      });
  };

  const handleSubmitAeyon = (data) => {
    search({ aeyonId: parseInt(state.search.id) })
      .then((response) => {
        dispatch({ type: "SET_SEARCH", payload: {} });
        dispatch({
          type: "SET_RESPONSE",
          payload: { beneficiaries: response.data },
        });
        navigate(`/search?by=aeyonId&id=${data.id}&showResults=true`);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleSearchSelect = (e) => {
    dispatch({ type: 'SET_SEARCH', payload: {} });
    navigate(`/search?by=${e.target.value}`)
  };

  const AeyonIdForm = () => {
    return (
      <Form
        className="existing-applicant-form"
        formName="search"
        buttonText="Search"
        submit={handleSubmitAeyon}
      >
        <div className="row align-items-start mb-5">
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control"
              label="Aeyon ID"
              name="id"
              formName='search'
            />
          </div>
        </div>
      </Form>
    )
  }

  const RefIdForm = () => {
    return (
      <Form
        className="existing-applicant-form"
        formName="search"
        buttonText="Search"
        // initialValues={initialValuesSearchForms}
        submit={handleSubmitRef}
      >
        <div className="row align-items-start mb-5">
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              classNameInput="form-control"
              label="Reference ID"
              name="id"
              formName='search'
            />
          </div>
        </div>
      </Form>
    )
  }

  const ContactForm = () => {
    return (
      <Form
        className="existing-applicant-form"
        formName="search"
        buttonText="Search"
        submit={handleSubmitContact}
      >
        <div className="row align-items-start mb-5">
            <div className="col-12 mb-3">
              <TextInput
                type="text"
                classNameInput="form-control"
                label="Name(s)"
                name="firstName"
                formName='search'
              />
            </div>
          </div>

          <div className="row align-items-start mb-5">
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                classNameInput="form-control"
                label="Date of Birth"
                name="dob"
                placeholder=""
                formName='search'

              />
            </div>
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                classNameInput="form-control"
                label="Residency Zipcode"
                name="currentZip"
                placeholder=""
                formName='search'

              />
            </div>
          </div>
        <div className="row align-items-start mb-5">
          <div className="form-check">
            <div>
              <label>
                <input
                  type="radio"
                  className="form-check-input"
                  id="radio1"
                  name="contactSearch"
                  value="primary_phone"
                  checked={selectedOption === "primary_phone"}
                  onChange={handleOptionChange}
                />
                phone
              </label>
            </div>

            <div>
              <label>
                <input
                  type="radio"
                  className="form-check-input"
                  id="radio2"
                  label="email"
                  name="contactSearch"
                  value="primary_email"
                  checked={selectedOption === "primary_email"}
                  onChange={handleOptionChange}
                />
                email
              </label>
            </div>
          </div>
          {selectedOption === "primary_phone" ?  (
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                formName="search"
                name="primary_phone"
                value={inputValue}
                classNameInput="form-control"
                placeholder="Primary phone"
              />
            </div>
          ): (
            <div className="col-6 mb-3">
              <TextInput
                type="text"
                formName="search"
                name="primary_email"
                value={inputValue}
                classNameInput="form-control"
                placeholder="Primary email"
              />
            </div>
          )}
        </div>
      </Form>
    );
  };


  const breadcrumbsArray = [{ key: 'key-1', text: 'Dashboard', url: '/dashboard' }, { key: 'key-2', text: 'Search', url: '/search' }]
  return (
    <>
      <div className="container-sm">
        <h2 className="form-title">Update Applicant</h2>
        <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        <div className="row align-items-start">
          <div onClick={() => handleSearchSelect({ target: { value: 'refId' } })} className="col-4 mb-3 all-applications">
            <div className="d-inline " id="svg-icon-container">
              <svg id="input-search-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6.5" cy="6.5" r="5.75" stroke="#C5C7CD" strokeWidth="1.5" />
                <path d="M11 11L15 15" stroke="#C5C7CD" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </div>
            <span className={`search-applicants search-nav  ${searchForm === "all" ? null : 'selected'}`}>
              Search
            </span>
          </div>
          <div onClick={() => handleAllApps()} className={`col-4 mb-3 all-applications ${searchForm === "all" ? 'selected' : null} padding-offset`}>
            <div className="d-inline " id="svg-icon-container">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M2.4 5.20002C3.2825 5.20002 4 4.48252 4 3.60002C4 2.71752 3.2825 2.00002 2.4 2.00002C1.5175 2.00002 0.8 2.71752 0.8 3.60002C0.8 4.48252 1.5175 5.20002 2.4 5.20002ZM13.6 5.20002C14.4825 5.20002 15.2 4.48252 15.2 3.60002C15.2 2.71752 14.4825 2.00002 13.6 2.00002C12.7175 2.00002 12 2.71752 12 3.60002C12 4.48252 12.7175 5.20002 13.6 5.20002ZM14.4 6.00002H12.8C12.36 6.00002 11.9625 6.17753 11.6725 6.46502C12.68 7.01753 13.395 8.01503 13.55 9.20003H15.2C15.6425 9.20003 16 8.84253 16 8.40003V7.60003C16 6.71753 15.2825 6.00002 14.4 6.00002ZM8 6.00002C9.5475 6.00002 10.8 4.74752 10.8 3.20002C10.8 1.65252 9.5475 0.400024 8 0.400024C6.4525 0.400024 5.2 1.65252 5.2 3.20002C5.2 4.74752 6.4525 6.00002 8 6.00002ZM9.92 6.80002H9.7125C9.1925 7.05002 8.615 7.20003 8 7.20003C7.385 7.20003 6.81 7.05002 6.2875 6.80002H6.08C4.49 6.80002 3.2 8.09003 3.2 9.68003V10.4C3.2 11.0625 3.7375 11.6 4.4 11.6H11.6C12.2625 11.6 12.8 11.0625 12.8 10.4V9.68003C12.8 8.09003 11.51 6.80002 9.92 6.80002ZM4.3275 6.46502C4.0375 6.17753 3.64 6.00002 3.2 6.00002H1.6C0.7175 6.00002 0 6.71753 0 7.60003V8.40003C0 8.84253 0.3575 9.20003 0.8 9.20003H2.4475C2.605 8.01503 3.32 7.01753 4.3275 6.46502Z" fill="#9FA2B4" />
              </svg>
            </div>
            <div className='d-inline ms-2 '>
              <span className='offgrey'>All Applications</span>
            </div>

          </div>
        </div>

        <div className="row align-items-start">
          <div className="col-12 mb-3" id="search-by-options">
            <label htmlFor="select-applicant-options" id="select-applicant-options">Search by: </label>

            <select
              name="select-applicant-options"
              id="select-applicant-options"
              value={searchForm ?? "refId"}
              onChange={(e) => handleSearchSelect(e)} >
              <option value="refId">Reference ID</option>
              <option value="aeyonId">Aeyon ID</option>
              <option value="contactInfo">Contact Info</option>
            </select>
          </div>
        </div>
        {/* {!showResults && (searchForm == 'contactInfo' ? ContactForm() : RefIdForm())} */}
        {!showResults && (
          searchForm === 'aeyonId' ? AeyonIdForm() :
            searchForm === 'contactInfo' ? ContactForm() :
              RefIdForm()
        )}


        {showResults && <AppSearchList />}

      </div>
      <HfBanner />
    </>
  );
}

export default UpdateAppSearch;